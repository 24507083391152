import React, { useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from "react-router-dom";

// Bootstrap
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';

import { FauxCheckBox } from '../../../lib/components/FauxCheckBox';

import { loadListing, submitListing, updateSingleField } from '../store/actions';
import { selectListing } from '../store/slice';

import { devLog } from '../../../lib/util/devLog';

// Prep default values
const getDefaultValues = (listing, defaultLang, langs = []) => {
  // Featured text i18n
  let featuredTexts = [];
  if (listing?.featuredOnLatest) {
    featuredTexts = Object.fromEntries(
      langs.map(lang => {
        let text = lang.code === defaultLang ? listing.featuredText : '';
        if (listing.i18n && listing.i18n[lang.code]) {
          text = listing.i18n[lang.code]?.featuredText || text;
        }
        return [`featuredText_${lang.code}`, text];
      })
    );
  }

  // Info text i18n
  const infoTexts = Object.fromEntries(
    langs.map(lang => {
      let text = lang.code === defaultLang ? listing.info : '';
      if (listing.i18n && listing.i18n[lang.code]) {
        text = listing.i18n[lang.code]?.info || text;
      }
      return [`infoText_${lang.code}`, text];
    })
  );

  // Offer codes i18n
  const offerCode = listing?.offerCode || listing?.offer?.code || '';

  const saving1 = listing?.offerSavings[0] ? listing?.offerSavings[0] :
    listing?.offer?.typicalSaving || '';
  const saving2 = listing?.offerSavings[1] ? listing?.offerSavings[1] :
    listing?.offer?.saving2 || '';
  const saving3 = listing?.offerSavings[2] ? listing?.offerSavings[2] :
    listing?.offer?.saving3 || '';

  const offer1Texts = Object.fromEntries(
    langs.map(lang => {
      let text = lang.code === defaultLang ? listing?.offer?.desc : '';
      if (listing.i18n && listing.i18n[lang.code]) {
        text = listing.i18n[lang.code].offerDescs ?
          listing.i18n[lang.code].offerDescs[0] : text;
      }
      return [`offer1Desc_${lang.code}`, text];
    })
  );

  const offer2Texts = Object.fromEntries(
    langs.map(lang => {
      let text = lang.code === defaultLang ? listing?.offer?.desc2 : '';
      if (listing.i18n && listing.i18n[lang.code]) {
        text = listing.i18n[lang.code].offerDescs ?
          listing.i18n[lang.code].offerDescs[1] : text;
      }
      return [`offer2Desc_${lang.code}`, text];
    })
  );

  const offer3Texts = Object.fromEntries(
    langs.map(lang => {
      let text = lang.code === defaultLang ? listing?.offer?.desc3 : '';
      if (listing.i18n && listing.i18n[lang.code]) {
        text = listing.i18n[lang.code].offerDescs ?
          listing.i18n[lang.code].offerDescs[2] : text;
      }
      return [`offer3Desc_${lang.code}`, text];
    })
  );

  // i18n: {
  //   // en: {
  //   //   info: String,
  //   //   offer: {
  //   //     code: String,
  //   //     desc1: String,
  //   //     saving1: String,
  //   //     desc2: String,
  //   //     saving2: String,
  //   //     desc3: String,
  //   //     saving3: String
  //   //   }
  //   // },
  // },

  return listing ?
    {
      // featuredText: listing.featuredText,
      isPublished: listing.published.customer,
      offerCode,
      saving1,
      saving2,
      saving3,
      ...featuredTexts,
      ...infoTexts,
      ...offer1Texts,
      ...offer2Texts,
      ...offer3Texts
    } :
    {};
};

export const EditExploreListing = () => {
  const dispatch = useDispatch();
  const loc = useLocation();

  const appLangs = useSelector(({ auth }) => auth.appLangs);

  const isLoadingSelected = useSelector(
    ({ explore }) => explore.isLoadingSelected
  );
  const listing = useSelector(
    ({ explore }) => explore.selectedListing
  );
  const selectedListingId = useSelector(
    ({ explore }) => explore.selectedListingId
  );
  const isSubmitting = useSelector(
    ({ explore }) => explore.isSubmitting
  );

  // Prep languages
  const thisAppLangs = listing ?
    appLangs.filter(al => al.appId === listing.custIds[0]) :
    [];
    
  const { defaultLang, langs } = thisAppLangs.length > 0 ?
    thisAppLangs[0] : { defaultLang: null, lang: [] };

  let defaultValues = getDefaultValues(
    listing || null,
    defaultLang,
    langs
  );

  const {
    clearErrors,
    control,
    handleSubmit,
    reset,
    setError,
    setValue,
    watch,
    formState: { errors, isSubmitSuccessful }
  } = useForm({
    defaultValues
  });

  useEffect(() => {
    if (selectedListingId && !listing) {
      dispatch(loadListing({ id: selectedListingId }));
    }
    else if (!selectedListingId) {
      const id = loc.pathname.substr(loc.pathname.lastIndexOf('/') + 1);
      dispatch(loadListing({ id }));
    }
  }, [listing]);

  // Reset the form on listing load
  useEffect(() => {
    if (listing) {
      reset(defaultValues);
    }
  }, [listing, reset]);

  if (isLoadingSelected === true || !listing) {
    return null;
  }

  // Event handlers

  // With have onSubmit and onSubmitForm below to allow manual clearErrors()
  // as react-hook-form requires manual clearing for general form errors.
  const onSubmit = formData => {
    if (isSubmitting) {
      return;
    }

    return dispatch(submitListing({
      data: formData,
      id: selectedListingId
    }))
      .unwrap()
      .then(({ data }) => {
        reset(getDefaultValues(data || null));
      })
      .catch(err => {
        if (err && err?.code === 401) {
          return dispatch(logOut());
        }
        else {
          setError(
            err.meta && err.meta.field ? err.meta.field : 'form',
            {
              type: 'server',
              message: err.error || err.toString()
            }
          );
        }
      });
  };
  const onSubmitForm = e => {
    clearErrors();
    return handleSubmit(onSubmit)(e);
  };

  const onClickFeaturedOnLatest = id => {
    if (window.confirm(
      'Are you sure you want to make this the Featured Offer?' +
      '\n\nIf another listing is already featured it will be unfeatured ' +
      'automatically.\n\nThis will happen immediately when you click OK.'
    )) {
      return dispatch(updateSingleField({
        id,
        field: 'featuredOnLatest',
        updateSelected: true,
        value: true
      }))
        .unwrap()
        .catch(err => {
          devLog('FEATURED ERROR', err);
        });
    }
  };

  const onClickUnfeaturedOnLatest = id => {
    return dispatch(updateSingleField({
      id,
      field: 'featuredOnLatest',
      updateSelected: true,
      value: false
    }))
      .unwrap()
      .catch(err => {
        devLog('UNFEATURED ERROR', err);
      });
  };

  const onClickShowOnLatest = id => {
    return dispatch(updateSingleField({
      id,
      field: 'showOnLatest',
      updateSelected: true,
      value: true
    }))
      .unwrap()
      .catch(err => {
        devLog('SHOW ERROR', err);
      });
  };

  const onClickUnshowOnLatest = id => {
    return dispatch(updateSingleField({
      id,
      field: 'showOnLatest',
      updateSelected: true,
      value: false
    }))
      .unwrap()
      .catch(err => {
        devLog('UNSHOW ERROR', err);
      });
  };

  // / Event Handlers

  // Fragment render fns

  const renderFeaturedOnLatestButton = id => {
    return <Button
      className="mb-2 w-100"
      onClick={e => {
        e.preventDefault();
        onClickFeaturedOnLatest(id);
      }}
      variant="success">
      <i className="bi-plus-lg" /> Feature on Latest
    </Button >;
  };

  const renderUnfeaturedOnLatestButton = id => {
    return <Button
      className="mb-2 w-100"
      onClick={e => {
        e.preventDefault();
        onClickUnfeaturedOnLatest(id);
      }}
      variant="danger">
      <i className="bi-dash-lg" /> Unfeature from Latest
    </Button >;
  };

  const renderShowOnLatestButton = id => {
    return <Button
      className="mb-2 w-100"
      onClick={e => {
        e.preventDefault();
        onClickShowOnLatest(id);
      }}
      variant="success">
      <i className="bi-plus-lg" /> Show on Latest
    </Button >;
  };

  const renderUnshowOnLatestButton = id => {
    return <Button
      className="mb-2 w-100"
      onClick={e => {
        e.preventDefault();
        onClickUnshowOnLatest(id);
      }}
      variant="danger">
      <i className="bi-dash-lg" /> Unshow from Latest
    </Button >;
  };

  // / Fragment render fns

  return (
    <Container className="mt-4">
      <h1 className="mb-4">
        <Link className="text-muted" to="/manage-explore">Explore</Link>
        {' / '}
        {listing.name}
      </h1>

      <Form onSubmit={onSubmitForm}>

        {isSubmitSuccessful && <Alert variant="success">
          Changes saved!
        </Alert>}

        {errors.form && <Alert variant="danger">
          {errors.form?.message}
        </Alert>}

        <Row className="mb-3">

          {/* Listing details */}
          <Col className="mb-3" lg={8} sm={12}>
            {listing.featuredOnLatest &&
              <Container className="bg-white mb-3 p-3">
                <Row><Col>
                  <Stack gap={3}>
                    {/* Featured Text */}
                    {langs.map(lang => <Form.Group key={`fttx_row_${lang.code}`}>
                      <span className="fs-7 text-muted text-uppercase">
                        FEATURED TEXT [{lang.name}]
                      </span>

                      <Controller
                        defaultValue=""
                        control={control}
                        name={`featuredText_${lang.code}`}
                        render={({
                          field: { onBlur, onChange, ref, value },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState
                        }) => (<Form.Control
                          as="textarea"
                          isInvalid={error}
                          onBlur={onBlur}
                          onChange={onChange}
                          placeholder="Featured text for the Latest screen..."
                          ref={ref}
                          rows="7"
                          value={value}
                          dir={lang.isRTL ? 'rtl' : 'ltr'}
                        />)}
                      />

                      {errors[`featuredText_${lang.code}`] &&
                        <Form.Control.Feedback type="invalid">
                          {errors[`featuredText_${lang.code}`]?.message}
                        </Form.Control.Feedback>}

                      <Form.Text className="fs-7 text-muted">
                        This descriptive text appears on the Latest screen, if
                        this Explore item is "Featured".
                      </Form.Text>
                    </Form.Group>)}
                  </Stack>
                </Col></Row>
              </Container>}

            {/* Info */}
            <Container className="bg-white mb-3 p-3">
              <Row><Col>
                <Stack gap={3}>
                  {langs.map(lang => <Form.Group key={`iftx_row_${lang.code}`}>
                    <span className="fs-7 text-muted text-uppercase">
                      INFO TEXT [{lang.name}]
                    </span>

                    <Controller
                      defaultValue=""
                      control={control}
                      name={`infoText_${lang.code}`}
                      render={({
                        field: { onBlur, onChange, ref, value },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState
                      }) => (<Form.Control
                        as="textarea"
                        isInvalid={error}
                        onBlur={onBlur}
                        onChange={onChange}
                        placeholder="Listing info for the Explore Details screen"
                        ref={ref}
                        rows="7"
                        value={value}
                        dir={lang.isRTL ? 'rtl' : 'ltr'}
                      />)}
                    />

                    {errors[`infoText_${lang.code}`] &&
                      <Form.Control.Feedback type="invalid">
                        {errors[`infoText_${lang.code}`]?.message}
                      </Form.Control.Feedback>}

                    <Form.Text className="fs-7 text-muted">
                      Listing info displayed in the app.
                    </Form.Text>
                  </Form.Group>)}
                </Stack>
              </Col></Row>
            </Container>

            {/* Offers */}
            <Container className="bg-white mb-3 p-3">
              <Row><Col>

                {/* Offer Code */}
                <Form.Group className="mb-3">
                  <span className="fs-7 text-muted text-uppercase">
                    Offer Code
                  </span>

                  <Controller
                    defaultValue=""
                    control={control}
                    name="offerCode"
                    render={({
                      field: { onBlur, onChange, ref, value },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState
                    }) => (<Form.Control
                      onBlur={onBlur}
                      onChange={onChange}
                      isInvalid={error}
                      placeholder="eg. ABC123"
                      ref={ref}
                      value={value}
                    />)}
                  />

                  {errors.offerCode && <Form.Control.Feedback type="invalid">
                    {errors.offerCode?.message}
                  </Form.Control.Feedback>}

                  <Form.Text className="fs-7 text-muted">
                    A 6-digit code for these offers.
                  </Form.Text>
                </Form.Group>

              </Col></Row>

              {/* Offer 1 */}
              <Row className="mb-3">

                {/* Offer Descriptions */}
                <Col>
                  <Stack gap={2}>

                    {langs.map((lang, i) =>
                      <Form.Group key={`o1ds_row_${lang.code}`}>
                        <span className="fs-7 text-muted text-uppercase">
                          Offer 1 Description [{lang.name}]
                        </span>

                        <Controller
                          defaultValue=""
                          control={control}
                          name={`offer1Desc_${lang.code}`}
                          render={({
                            field: { onBlur, onChange, ref, value },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState
                          }) => (<Form.Control
                            onBlur={onBlur}
                            onChange={onChange}
                            isInvalid={error}
                            placeholder="eg. 30% off all food and drink"
                            ref={ref}
                            value={value}
                            dir={lang.isRTL ? 'rtl' : 'ltr'}
                            maxLength={40}
                          />)}
                        />

                        {errors[`offer1Desc_${lang.code}`] &&
                          <Form.Control.Feedback type="invalid">
                            {errors[`offer1Desc_${lang.code}`]?.message}
                          </Form.Control.Feedback>}

                        {(i === langs.length - 1) &&
                          <Form.Text className="fs-7 text-muted">
                            Brief offer text, max 40 characters.
                          </Form.Text>}
                      </Form.Group>)}

                  </Stack>
                </Col>

                {/* Typical Saving */}
                <Col>
                  <Form.Group className="mb-3">
                    <span className="fs-7 text-muted text-uppercase">
                      Offer 1 Typical Saving
                    </span>

                    <Controller
                      defaultValue=""
                      control={control}
                      name="saving1"
                      render={({
                        field: { onBlur, onChange, ref, value },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState
                      }) => (<Form.Control
                        onBlur={onBlur}
                        onChange={onChange}
                        isInvalid={error}
                        placeholder="eg. 50"
                        ref={ref}
                        value={value}
                      />)}
                    />

                    {errors.saving1 && <Form.Control.Feedback type="invalid">
                      {errors.saving1?.message}
                    </Form.Control.Feedback>}

                    <Form.Text className="fs-7 text-muted">
                      Typical saving, no currency symbol.
                    </Form.Text>
                  </Form.Group>

                </Col>
              </Row>

              {/* Offer 2 */}
              <Row className="mb-3">

                {/* Offer Descriptions */}
                <Col>
                  <Stack gap={2}>

                    {langs.map((lang, i) =>
                      <Form.Group key={`o2ds_row_${lang.code}`}>
                        <span className="fs-7 text-muted text-uppercase">
                          Offer 2 Description [{lang.name}]
                        </span>

                        <Controller
                          defaultValue=""
                          control={control}
                          name={`offer2Desc_${lang.code}`}
                          render={({
                            field: { onBlur, onChange, ref, value },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState
                          }) => (<Form.Control
                            onBlur={onBlur}
                            onChange={onChange}
                            isInvalid={error}
                            placeholder="eg. 30% off all food and drink"
                            ref={ref}
                            value={value}
                            dir={lang.isRTL ? 'rtl' : 'ltr'}
                            maxLength={40}
                          />)}
                        />

                        {errors[`offer2Desc_${lang.code}`] &&
                          <Form.Control.Feedback type="invalid">
                            {errors[`offer2Desc_${lang.code}`]?.message}
                          </Form.Control.Feedback>}

                        {(i === langs.length - 1) &&
                          <Form.Text className="fs-7 text-muted">
                            Brief offer text, max 40 characters.
                          </Form.Text>}
                      </Form.Group>)}

                  </Stack>
                </Col>

                {/* Typical Saving */}
                <Col>
                  <Form.Group className="mb-3">
                    <span className="fs-7 text-muted text-uppercase">
                      Offer 2 Typical Saving
                    </span>

                    <Controller
                      defaultValue=""
                      control={control}
                      name="saving2"
                      render={({
                        field: { onBlur, onChange, ref, value },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState
                      }) => (<Form.Control
                        onBlur={onBlur}
                        onChange={onChange}
                        isInvalid={error}
                        placeholder="eg. 50"
                        ref={ref}
                        value={value}
                      />)}
                    />

                    {errors.saving2 && <Form.Control.Feedback type="invalid">
                      {errors.saving2?.message}
                    </Form.Control.Feedback>}

                    <Form.Text className="fs-7 text-muted">
                      Typical saving, no currency symbol.
                    </Form.Text>
                  </Form.Group>

                </Col>
              </Row>

              {/* Offer 3 */}
              <Row>

                {/* Offer Descriptions */}
                <Col>
                  <Stack gap={2}>

                    {langs.map((lang, i) =>
                      <Form.Group key={`o3ds_row_${lang.code}`}>
                        <span className="fs-7 text-muted text-uppercase">
                          Offer 3 Description [{lang.name}]
                        </span>

                        <Controller
                          defaultValue=""
                          control={control}
                          name={`offer3Desc_${lang.code}`}
                          render={({
                            field: { onBlur, onChange, ref, value },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState
                          }) => (<Form.Control
                            onBlur={onBlur}
                            onChange={onChange}
                            isInvalid={error}
                            placeholder="eg. 30% off all food and drink"
                            ref={ref}
                            value={value}
                            dir={lang.isRTL ? 'rtl' : 'ltr'}
                            maxLength={40}
                          />)}
                        />

                        {errors[`offer3Desc_${lang.code}`] &&
                          <Form.Control.Feedback type="invalid">
                            {errors[`offer3Desc_${lang.code}`]?.message}
                          </Form.Control.Feedback>}

                        {(i === langs.length - 1) &&
                          <Form.Text className="fs-7 text-muted">
                            Brief offer text, max 40 characters.
                          </Form.Text>}
                      </Form.Group>)}

                  </Stack>
                </Col>

                {/* Typical Saving */}
                <Col>
                  <Form.Group className="mb-3">
                    <span className="fs-7 text-muted text-uppercase">
                      Offer 3 Typical Saving
                    </span>

                    <Controller
                      defaultValue=""
                      control={control}
                      name="saving3"
                      render={({
                        field: { onBlur, onChange, ref, value },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState
                      }) => (<Form.Control
                        onBlur={onBlur}
                        onChange={onChange}
                        isInvalid={error}
                        placeholder="eg. 50"
                        ref={ref}
                        value={value}
                      />)}
                    />

                    {errors.saving3 && <Form.Control.Feedback type="invalid">
                      {errors.saving3?.message}
                    </Form.Control.Feedback>}

                    <Form.Text className="fs-7 text-muted">
                      Typical saving, no currency symbol.
                    </Form.Text>
                  </Form.Group>

                </Col>
              </Row>

            </Container>

            <Container className="bg-white mb-3 p-3">
              <Row>
                <Col>

                  {/* Published */}
                  <Form.Group>
                    <span className="fs-7 text-muted text-uppercase">
                      PUBLISH LISTING
                    </span>

                    <Controller
                      control={control}
                      defaultValue={false}
                      name="isPublished"
                      render={({
                        field: { name, ref, value }
                      }) => {
                        return (
                          <div
                            onClick={() => setValue(
                              'isPublished',
                              value === true ? false : true
                            )}
                            ref={ref}
                            role="button"
                          >
                            <FauxCheckBox
                              active={value}
                              label="Published"
                            />
                          </div>
                        );
                      }}
                    />

                    {errors.isPublished &&
                      <Form.Control.Feedback type="invalid">
                        {errors.isPublished?.message}
                      </Form.Control.Feedback>}

                    <Form.Text className="fs-7 text-muted">
                      Make the listing immediately available in the app.
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
            </Container>

            {/* <Container className="bg-white p-3">
              <Row>
                <Col className="mb-3" lg={6}>
                  <Form.Group>
                    <span className="fs-7 text-muted text-uppercase">
                      Info
                    </span>

                    <Controller
                      defaultValue=""
                      control={control}
                      name="info"
                      render={({
                        field: { onBlur, onChange, ref, value },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState
                      }) => (<Form.Control
                        as="textarea"
                        isInvalid={error}
                        onBlur={onBlur}
                        onChange={onChange}
                        placeholder="Simple text description of your location..."
                        ref={ref}
                        rows="7"
                        value={value}
                      />)}
                      rules={{
                        required: {
                          message: 'Please enter location info.',
                          value: true
                        }
                      }}
                    />

                    {errors.info && <Form.Control.Feedback type="invalid">
                      {errors.info?.message}
                    </Form.Control.Feedback>}

                    <Form.Text className="fs-7 text-muted">
                      A simple, text-only description of your location.
                    </Form.Text>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <span className="fs-7 text-muted text-uppercase">
                      Telephone number
                    </span>

                    <Controller
                      defaultValue=""
                      control={control}
                      name="phoneNumber"
                      render={({
                        field: { onBlur, onChange, ref, value },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState
                      }) => (<Form.Control
                        onBlur={onBlur}
                        onChange={onChange}
                        isInvalid={error}
                        placeholder="Phone number"
                        ref={ref}
                        type="tel"
                        value={value}
                      />)}
                    />

                    {errors.phoneNumber && <Form.Control.Feedback type="invalid">
                      {errors.phoneNumber?.message}
                    </Form.Control.Feedback>}

                    <Form.Text className="fs-7 text-muted">
                      A contact phone number. Include international dialing
                      codes if users should be able to call you from anywhere.
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <span className="fs-7 text-muted text-uppercase">
                      Booking URL
                    </span>

                    <Controller
                      defaultValue=""
                      control={control}
                      name="ticketUrl"
                      render={({
                        field: { onBlur, onChange, ref, value },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState
                      }) => (<Form.Control
                        onBlur={onBlur}
                        onChange={onChange}
                        isInvalid={error}
                        placeholder="Url"
                        ref={ref}
                        type="url"
                        value={value}
                      />)}
                    />

                    {errors.ticketUrl && <Form.Control.Feedback type="invalid">
                      {errors.ticketUrl?.message}
                    </Form.Control.Feedback>}

                    <Form.Text className="fs-7 text-muted">
                      The URL of a website which users can visit for more info,
                      or to make bookings directly.
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
            </Container> */}
          </Col>

          {/* Listing sidebar */}
          <Col lg={4}>
            <Stack gap={2}>
              <div className="bg-white p-3 mb-2">
                <Button
                  className="w-100"
                  type="submit"
                  variant="primary"
                >
                  Save Changes
                </Button>
              </div>
              {listing?.showOnLatest &&
                <div className="bg-white p-3 pb-3 mb-2">
                  {listing?.featuredOnLatest &&
                    renderUnfeaturedOnLatestButton(listing.id)}
                  {!listing?.featuredOnLatest && listing?.showOnLatest &&
                    renderFeaturedOnLatestButton(listing.id)}
                  <Form.Text className="fs-7 text-muted">
                    If this listing is already shown on the Latest screen you can
                    make it the Featured Offer.
                  </Form.Text>
                </div>}
              <div className="bg-white p-3 pb-3 mb-2">
                {listing?.showOnLatest &&
                  renderUnshowOnLatestButton(listing.id)}
                {!listing.showOnLatest &&
                  renderShowOnLatestButton(listing.id)}
                <Form.Text className="fs-7 text-muted">
                  Show this listing's offer on the app's Latest screen?
                </Form.Text>
              </div>
            </Stack>
          </Col>
        </Row>

      </Form>
    </Container>
  );
};
