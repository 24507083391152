import React, { useEffect } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import Datetime from 'react-datetime';
import Countdown from 'react-countdown';
import moment from 'moment-timezone';

// Bootstrap
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import Table from 'react-bootstrap/Table';

// Components
import { FlashDealStatusPill } from
  '../../myListings/components/FlashDealStatusPill';

// Actions
import {
  loadFlashDeal,
  submitApprove,
  submitReject
} from '../store/actions';
import { logOut } from '../../auth/store/actions';

// Util
import { devLog } from '../../../lib/util/devLog';
import { friendlyDate } from '../../../lib/util/dates';

import { defaultAccess } from '../../../../common/config';

// Show a list of errors  
const showErrorsList = errors => {
  return (
    <ul className="mb-0">
      {errors.map((error, index) => <li key={`erlst_${index}`}>{error}</li>)}
    </ul>
  );
};

// Prep default values
const getDefaultValues = (selectedDeal, defaultLang, langs = []) => {
  // FD summary i18n
  const summaryTexts = Object.fromEntries(
    langs.map(lang => {
      let text = lang.code === defaultLang ? selectedDeal.summary : '';
      if (selectedDeal.i18n && selectedDeal.i18n[lang.code]) {
        text = selectedDeal.i18n[lang.code]?.details || text;
      }
      return [`summary_${lang.code}`, text];
    })
  );

  // FD details i18n
  const detailsTexts = Object.fromEntries(
    langs.map(lang => {
      let text = lang.code === defaultLang ? selectedDeal.details : '';
      if (selectedDeal.i18n && selectedDeal.i18n[lang.code]) {
        text = selectedDeal.i18n[lang.code]?.details || text;
      }
      return [`details_${lang.code}`, text];
    })
  );

  return selectedDeal ?
    {
      reason: '',
      selectedFlashDealId: selectedDeal ? selectedDeal.id : null,
      ...summaryTexts,
      ...detailsTexts
    } :
    {};
};

export const ModerateFlashDealForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const appLangs = useSelector(({ auth }) => auth.appLangs);

  const selectedDeal = useSelector(
    ({ moderate }) => moderate.selectedFlashDeal
  );
  const selectedId = useSelector(
    ({ moderate }) => moderate.selectedFlashDealId
  );

  const isLoading = useSelector(
    ({ moderate }) => moderate.isLoading
  );
  const isSubmitting = useSelector(
    ({ moderate }) => moderate.isSubmitting
  );

  // Prep languages
  const thisAppLangs = selectedDeal ?
    appLangs.filter(al => al.appId === selectedDeal.appId) :
    [];

  const { defaultLang, langs } = thisAppLangs.length > 0 ?
    thisAppLangs[0] : { defaultLang: null, lang: [] };

  // Get form defaults
  // const defaultValues = { reason: '' };
  let defaultValues = getDefaultValues(
    selectedDeal || null,
    defaultLang,
    langs
  );

  const {
    clearErrors,
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors, isSubmitSuccessful }
  } = useForm({
    defaultValues
  });

  // Load the FD if needed
  useEffect(() => {
    if (selectedId && !selectedDeal && !isLoading) {
      // console.log('UE[selectedDeal] load', selectedId, selectedDeal, isLoading);
      dispatch(loadFlashDeal({ id: selectedId }));
    }
  }, [selectedDeal]);

  // Reset the form on FD load
  useEffect(() => {
    if (selectedDeal && !isSubmitSuccessful) {
      reset(defaultValues);
    }
  }, [selectedDeal, reset]);

  // Event handlers

  // With have onSubmit and onSubmitForm below to allow manual clearErrors()
  // as react-hook-form requires manual clearing for general form errors.
  const onSubmit = (data = {}, action = 'reject') => {
    if (isSubmitting) {
      return;
    }

    if (action === 'reject' && data?.reason === '') {
      setError('reason', {
        message: 'A reason should be given for rejection.'
      });
      return;
    }

    const submitFn = action === 'approve' ? submitApprove : submitReject;

    return dispatch(submitFn({ data }))
      .unwrap()
      .then(() => {
        setTimeout(() => navigate('/moderate-flash-deals'), 5000);
      })
      .catch(err => {
        if (err && err?.code === 401) {
          return dispatch(logOut());
        }
        else {
          // console.log('ERROR?', err);
          setError(
            err.meta && err.meta.field ? err.meta.field : 'form',
            {
              type: 'server',
              message: err.error || err.toString()
            }
          );
        }
      });
  };

  const onSubmitApprove = e => {
    if (window.confirm('Please confirm APPROVAL.')) {
      clearErrors();
      return handleSubmit(data => {
        onSubmit(data, 'approve');
      }, (err) => {
        // Catching submission errors
      })(e);
    }
    else {
      e.preventDefault();
    }
  };

  const onSubmitReject = e => {
    if (window.confirm('Please confirm REJECTION.')) {
      clearErrors();
      return handleSubmit(data => {
        onSubmit(data, 'reject');
      }, (err) => {
        // Catching submission errors
      })(e);
    }
    else {
      e.preventDefault();
    }
  };

  // / Event handlers

  // Render functions

  const renderHistory = (history = []) => {
    // Sort history by desc date
    const sorted = [...history].sort((a, b) => {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.dateTime) - new Date(a.dateTime);
    });

    return (
      <Container className="bg-white p-3 mt-3">
        <h6 className="text-muted">History</h6>
        <Table striped borderless hover>
          <tbody>
            {sorted && sorted.map(
              h => (
                <tr key={`fdhst_${h.dateTime}`}><td>
                  <Row className="px-3 py-1 align-items-center">
                    <Col className="p-0 text-muted w-25">Status</Col>
                    <Col className="p-0" xs="8">
                      {h.status.toUpperCase()}
                    </Col>
                  </Row>
                  <Row className="px-3 py-1 align-items-center">
                    <Col className="p-0 text-muted w-25">Date/Time</Col>
                    <Col className="p-0" xs="8">
                      {friendlyDate(h.dateTime)}
                    </Col>
                  </Row>
                  {h.info && <Row className="px-3 py-1 align-items-center">
                    <Col className="p-0" xs="8">
                      {h.info}
                    </Col>
                  </Row>}
                </td></tr>
              )
            )}
          </tbody>
        </Table>
      </Container>
    );
  };

  const renderApproveButton = () => {
    return <Button
      className="w-100"
      onClick={onSubmitApprove}
      type="submit"
      variant="primary"
    >
      APPROVE Flash Deal
    </Button>;
  };

  const renderRejectButton = () => {
    return <Button
      className="w-100 mb-2"
      onClick={onSubmitReject}
      type="submit"
      variant="danger"
    >
      REJECT Flash Deal
    </Button>;
  };

  // / Render functions

  // Alert text
  let alertVariant = '';
  let alertText = '';
  if (selectedDeal && !isSubmitting) {
    switch (selectedDeal.status) {
      case 'approved':
        alertVariant = 'success';
        alertText = 'Flash Deal approved.';
        break;
      case 'rejected':
        alertVariant = 'danger';
        alertText = 'Flash Deal rejected.';
        break;
    }
    // console.log('STATUS!', selectedDeal.status, alertVariant, alertText);
  }

  return (
    <>
      {selectedDeal && <>
        <h1 className="mb-4">
          <Link className="text-muted" to="/moderate-flash-deals">
            Moderate Flash Deals
          </Link>
          {' / Moderate'}
        </h1>

        <Form>

          {isSubmitSuccessful && <Alert variant={alertVariant}>
            {alertText} <span className="text-muted">
              Back to moderation list in <Countdown
                date={Date.now() + 5000}
                renderer={({ seconds }) => <span>{seconds}s</span>}
              />
            </span>
          </Alert>}

          {errors.form && <Alert variant="danger">
            {errors.form?.message}
          </Alert>}

          <Row className="mb-3">
            {/* Flash Deal details */}
            <Col className="mb-3" lg={8} sm={12}>
              <Container className="bg-white mb-3 p-3">
                <Row>
                  {/* LEFT COLUMN */}
                  <Col lg={6}>

                    {/* Listing Name */}
                    <Form.Group>
                      <span className="fs-7 text-muted text-uppercase">
                        Editing Flash Deal for this listing:
                      </span>

                      <Form.Control
                        disabled={true}
                        value={selectedDeal.name}
                      />

                      <Form.Text className="fs-7 text-muted">
                        The Explore listing the Flash Deal is associated with.
                      </Form.Text>
                    </Form.Group>
                  </Col>

                  {/* RIGHT COLUMN */}
                  <Col lg={6}>

                    {/* Flash Deal Code */}
                    <Form.Group>
                      <span className="fs-7 text-muted text-uppercase">
                        Flash Deal Code
                      </span>

                      <Form.Control
                        disabled={true}
                        value={selectedDeal.code}
                      />

                      <Form.Text className="fs-7 text-muted">
                        The Flash Deal's unique code, generated automatically
                        when the Flash Deal is first submitted.
                      </Form.Text>
                    </Form.Group>

                  </Col>
                </Row>

                <Row>
                  {/* LEFT COLUMN */}
                  <Col lg={6}>

                    {/* Start Date and Time */}
                    <Form.Group className="mb-3">
                      <span className="fs-7 text-muted text-uppercase">
                        Start Date and Time
                      </span>

                      <Form.Control
                        disabled={true}
                        value={moment(selectedDeal.from)
                          .format('MMM Do YYYY HH:mm')}
                      />

                      <Form.Text className="fs-7 text-muted">
                        The date and time the Flash Deal should begin. It must
                        be at least 24 hours from the time of submission.
                      </Form.Text>
                    </Form.Group>

                  </Col>

                  {/* RIGHT COLUMN */}
                  <Col lg={6}>

                    {/* End Date and Time */}
                    <Form.Group className="mb-3">
                      <span className="fs-7 text-muted text-uppercase">
                        End Date and Time
                      </span>

                      <Form.Control
                        disabled={true}
                        value={moment(selectedDeal.to)
                          .format('MMM Do YYYY HH:mm')}
                      />

                      <Form.Text className="fs-7 text-muted">
                        The date and time of the end of the Flash Deal. It must
                        be a maximum of 7 days (full 24 hour periods) from the
                        Start Date.
                      </Form.Text>
                    </Form.Group>

                  </Col>
                </Row>
              </Container>

              {/* i18n offer and details */}
              <Container className="bg-white p-3">
                {/* Short Summary */}
                <Row className="mb-3"><Col>
                  <Stack gap={2}>

                    {langs.map((lang, i) =>
                      <Form.Group key={`dtls_row_${lang.code}`}>
                        <span className="fs-7 text-muted text-uppercase">
                          Short Summary [{lang.name}]
                        </span>

                        <Controller
                          defaultValue=""
                          control={control}
                          name={`summary_${lang.code}`}
                          render={({
                            field: { onBlur, onChange, ref, value },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState
                          }) => (<Form.Control
                            onBlur={onBlur}
                            onChange={onChange}
                            isInvalid={error}
                            ref={ref}
                            value={value}
                            maxLength={50}
                            dir={lang.isRTL ? 'rtl' : 'ltr'}
                          />)}
                        />

                        {errors[`summary_${lang.code}`] &&
                          <Form.Control.Feedback type="invalid">
                            {errors[`summary_${lang.code}`]?.message}
                          </Form.Control.Feedback>}

                        {(i === langs.length - 1) &&
                          <Form.Text className="fs-7 text-muted">
                            A short summary of the deal. Maximum of 50
                            characters.
                          </Form.Text>}
                      </Form.Group>)}

                  </Stack>
                </Col></Row>

                {/* Details */}
                <Row><Col>
                  <Stack gap={2}>

                    {langs.map((lang, i) =>
                      <Form.Group key={`dtls_row_${lang.code}`}>
                        <span className="fs-7 text-muted text-uppercase">
                          Details [{lang.name}]
                        </span>

                        <Controller
                          defaultValue=""
                          control={control}
                          name={`details_${lang.code}`}
                          render={({
                            field: { onBlur, onChange, ref, value },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState
                          }) => (<Form.Control
                            as="textarea"
                            isInvalid={error}
                            onBlur={onBlur}
                            onChange={onChange}
                            ref={ref}
                            rows="7"
                            value={value}
                            dir={lang.isRTL ? 'rtl' : 'ltr'}
                          />)}
                        />

                        {errors[`details_${lang.code}`] &&
                          <Form.Control.Feedback type="invalid">
                            {errors[`details_${lang.code}`]?.message}
                          </Form.Control.Feedback>}

                        {(i === langs.length - 1) &&
                          <Form.Text className="fs-7 text-muted">
                            Full details of the deal, including any
                            conditions, exclusions or requirements.
                          </Form.Text>}
                      </Form.Group>)}

                  </Stack>
                </Col></Row>
              </Container>

              {selectedDeal?.history.length > 0 &&
                renderHistory(selectedDeal.history)}
            </Col>

            {/* Set Up FD sidebar */}
            <Col lg={4}>
              <Stack gap={3}>
                <div className="bg-white p-3">
                  <Row className="bg-white px-3 align-items-center">
                    <Col className="px-0 text-muted w-25">Status</Col>
                    <Col className="px-0 align-items-center" xs="8">
                      <FlashDealStatusPill status={selectedDeal.status} />
                    </Col>
                  </Row>
                </div>

                <div className="bg-white p-3">
                  {renderApproveButton()}
                </div>

                <div className="bg-white p-3">
                  {renderRejectButton()}

                  <Form.Group>
                    <Controller
                      control={control}
                      name="reason"
                      render={({
                        field: { onBlur, onChange, ref, value },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState
                      }) => (<Form.Control
                        as="textarea"
                        isInvalid={error}
                        onBlur={onBlur}
                        onChange={onChange}
                        placeholder="Reason for rejection..."
                        ref={ref}
                        rows="5"
                        value={value}
                      />)}
                    />

                    {errors.reason && <Form.Control.Feedback type="invalid">
                      {errors.reason?.message}
                    </Form.Control.Feedback>}

                    <Form.Text className="fs-7 text-muted">
                      Please state a brief reason for rejecting this Flash Deal.
                    </Form.Text>
                  </Form.Group>
                </div>
              </Stack>
            </Col>
          </Row>

        </Form>
      </>}
    </>
  );
};
