'use strict';

import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiGet, apiPost } from '../../../lib/util/api';

// Load Flash Deals list
export const loadFlashDealsList = createAsyncThunk(
  'moderateFlashDeals/loadAll',
  async (opts = {}, { rejectWithValue }) => {
    try {
      // Send the request
      const response = await apiGet('/cp-api/moderate-flash-deals');
      if (response.status === 200) {
        return response.data;
      }
      throw { code: response.status };
    }
    catch (err) {
      return rejectWithValue(err);
    }
  }
);

// Load Flash Deal
export const loadFlashDeal = createAsyncThunk(
  'moderateFlashDeals/load',
  async (opts = {}, { rejectWithValue }) => {
    let { id } = opts;

    try {
      // Send the request
      const response = await apiGet(`/cp-api/moderate-flash-deals/${id}`);
      if (response.status === 200) {
        return response.data;
      }
      throw { code: response.status };
    }
    catch (err) {
      return rejectWithValue(err);
    }
  }
);

// Submit Flash Deal approval
export const submitApprove = createAsyncThunk(
  'moderateFlashDeals/submitApprove',
  async (opts, { getState, rejectWithValue, requestId }) => {
    // console.log('APPROVE ACTION', opts);
    const { selectedFlashDealId, ...data } = opts.data;

    const {
      currentRequestId,
      isSubmitting
    } = getState().moderate;
    if (
      !isSubmitting ||
      requestId !== currentRequestId
    ) {
      return;
    }

    try {
      const response = await apiPost(
        `/cp-api/moderate-flash-deals/${selectedFlashDealId}/approve`,
        { data }
      );

      return response.data;
    }
    catch (err) {
      throw rejectWithValue(err);
    }
  }
);

// Submit Flash Deal rejection
export const submitReject = createAsyncThunk(
  'moderateFlashDeals/submitReject',
  async (opts = { data: null }, { getState, rejectWithValue, requestId }) => {
    const { reason, selectedFlashDealId } = opts.data;
    // console.log('REJECT ACTION', reason);

    const {
      currentRequestId,
      isSubmitting
    } = getState().moderate;
    if (
      !isSubmitting ||
      requestId !== currentRequestId
    ) {
      return;
    }

    try {
      const response = await apiPost(
        `/cp-api/moderate-flash-deals/${selectedFlashDealId}/reject`,
        { data: { reason } }
      );

      return response.data;
    }
    catch (err) {
      throw rejectWithValue(err);
    }
  }
);
