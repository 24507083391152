import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";

// Bootstrap
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import Table from 'react-bootstrap/Table';

// Components
import { EmbeddedTable } from '../../../lib/components/EmbeddedTable';
import { FauxCheckBox } from '../../../lib/components/FauxCheckBox';

// Actions
import {
  loadSupplier,
  submitAddSupplier
} from '../store/actions';
import { logOut } from '../../auth/store/actions';
// import {
//   addAddFormExploreItem,
//   removeAddFormExploreItem,

//   addExploreItem,
//   removeExploreItem,

//   clearExploreSearchResults
// } from '../store/slice';

// Util
import { devLog } from '../../../lib/util/devLog';

// Prep default values
const getDefaultValues = (supplier, appsForFilter) => {
  // Allowed apps defaults
  const allowedAppsDefaults =  Object.fromEntries(
    appsForFilter.map(app => [
      `allowedApps_${app.id}`,
      supplier?.appIds.some(appId => appId === app.id) || null
    ])
  );

  const name = supplier?.name || '';
  const email = supplier?.email || '';
  // const isActive = supplier?.active || false;

  return {
    ...allowedAppsDefaults,
    // isActive,
    name,
    email
  }
};

export const AddSupplierForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const appsForFilter = useSelector(
    ({ manageSuppliers }) => manageSuppliers.appsForFilter
  );
  const isSubmitting = useSelector(
    ({ manageSuppliers }) => manageSuppliers.isSubmitting
  );

  // Get form defaults
  let defaultValues = getDefaultValues(null, appsForFilter);

  const {
    clearErrors,
    control,
    getValues,
    handleSubmit,
    reset,
    setError,
    setValue,
    watch,
    formState: { errors, isSubmitSuccessful }
  } = useForm({
    defaultValues
  });

  // Extract appIds from the separate allowed apps fields
  const getAppIdsFromFields = data => {
    const appIds = [];
    const fieldKeys = Object.keys(data);
    for (let i = 0; i < fieldKeys.length; i++) {
      if (
        fieldKeys[i].indexOf('allowedApps_') === 0 &&
        data[fieldKeys[i]] === true
      ) {
        appIds.push(fieldKeys[i].slice(12));
      }
    }

    return appIds.join(',');
  };

  // Event handlers

  // With have onSubmit and onSubmitForm below to allow manual clearErrors()
  // as react-hook-form requires manual clearing for general form errors.
  const onSubmit = data => {
    if (isSubmitting) {
      return;
    }

    // Get allowed app IDs
    const appIds = getAppIdsFromFields(data);

    // Extract data from formdata object, and add processed fields
    const submitData = (({ name, email }) =>
      ({ name, email }))(data);

    submitData.appIds = appIds;

    return dispatch(submitAddSupplier(submitData))
      .unwrap()
      .then(() => {
        return navigate('/manage-suppliers');
      })
      .catch(err => {
        if (err && err?.code === 401) {
          return dispatch(logOut());
        }
        else {
          setError(
            err?.meta && err?.meta.field ? err.meta.field : 'form',
            {
              type: 'server',
              message: err.error || err.toString()
            }
          );
        }
      });
  };
  const onSubmitForm = e => {
    clearErrors();
    return handleSubmit(onSubmit)(e);
  };

  // / Event handlers

  return (
    <>
      <h1 className="mb-4">
        <Link className="text-muted" to="/manage-suppliers">Manage Suppliers</Link>
        {' / Add Supplier'}
      </h1>

      <Form onSubmit={onSubmitForm}>

        {isSubmitSuccessful && <Alert variant="success">
          Changes saved!
        </Alert>}

        {errors.form && <Alert variant="danger">
          {errors.form?.message}
        </Alert>}

        <Row className="mb-3">
          {/* Supplier details */}
          <Col className="mb-3" lg={8} sm={12}>
            <Container className="bg-white p-3">
              <Row>

                {/* LEFT COLUMN */}
                <Col className="mb-3" lg={6}>

                  {/* Name */}
                  <Form.Group className="mb-3">
                    <span className="fs-7 text-muted text-uppercase">
                      Name
                    </span>

                    <Controller
                      defaultValue=""
                      control={control}
                      name="name"
                      render={({
                        field: { onBlur, onChange, ref, value },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState
                      }) => (<Form.Control
                        onBlur={onBlur}
                        onChange={onChange}
                        isInvalid={error}
                        placeholder="eg. Hilton"
                        ref={ref}
                        value={value}
                      />)}
                      rules={{ required: true }}
                    />

                    {errors.name && <Form.Control.Feedback type="invalid">
                      {errors.name?.message}
                    </Form.Control.Feedback>}

                    <Form.Text className="fs-7 text-muted">
                      The name of the organisation. It may be the same as an
                      Explore location, if they only have one, or the name of a
                      group which oversees multiple locations.
                    </Form.Text>
                  </Form.Group>

                  {/* Contact email address */}
                  <Form.Group className="mb-3">
                    <span className="fs-7 text-muted text-uppercase">
                      Contact Email Address
                    </span>

                    <Controller
                      defaultValue=""
                      control={control}
                      name="email"
                      render={({
                        field: { onBlur, onChange, ref, value },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState
                      }) => (<Form.Control
                        onBlur={onBlur}
                        onChange={onChange}
                        isInvalid={error}
                        placeholder="eg. yourname@mailservice.com"
                        ref={ref}
                        type="email"
                        value={value}
                      />)}
                    />

                    {errors.email && <Form.Control.Feedback type="invalid">
                      {errors.email?.message}
                    </Form.Control.Feedback>}

                    <Form.Text className="fs-7 text-muted">
                      Used only to receive account status notifications, such as
                      confirmations of payments which affect all of the 
                      supplier's logins and Explore locations. Only enter an
                      address which can securely receive that kind of
                      notification in agreement with the Supplier. This not used
                      as a login for the app or Control Panel. It will not
                      receive regular Control Panel notifications, such as
                      for new Enquiries. While is is desirable, it is optional,
                      in the event the Supplier does not want to provide one.
                    </Form.Text>
                  </Form.Group>

                </Col>

                {/* RIGHT COLUMN */}
                <Col lg={6}>

                  {/* Allowed Apps */}
                  <Form.Group className="mb-3">
                    <span className="fs-7 text-muted text-uppercase">
                      Allowed Apps
                    </span>

                    {appsForFilter.map(app => <Controller
                      control={control}
                      defaultValue={false}
                      key={`allowedApps_${app.id}`}
                      name={`allowedApps_${app.id}`}
                      render={({
                        field: { name, ref, value }
                      }) => {
                        return (
                          <Stack
                            className="my-1"
                            direction="horizontal"
                            gap={2}

                            onClick={() => {
                              setValue(
                                `allowedApps_${app.id}`,
                                value === true ? false : true
                              )
                            }}
                            ref={ref}
                            role="button"
                          >
                            {value && <i className="bi-check-square-fill text-primary" />}
                            {!value && <i className="bi-square" />}
                            <div>{app.label}</div>
                          </Stack>
                        );
                      }}
                    />)}

                    <Form.Text className="fs-7 text-muted">
                      The app(s) this login can access data for.
                    </Form.Text>
                  </Form.Group>

                  {/* Active */}
                  {/* <Form.Group>
                    <span className="fs-7 text-muted text-uppercase">
                      Is the Supplier account active?
                    </span>

                    <Controller
                      control={control}
                      defaultValue={false}
                      name="isActive"
                      render={({
                        field: { name, ref, value }
                      }) => {
                        return (
                          <div
                            onClick={() => setValue(
                              'isActive',
                              value === true ? false : true
                            )}
                            ref={ref}
                            role="button"
                          >
                            <FauxCheckBox
                              active={value}
                              label="Active"
                            />
                          </div>
                        );
                      }}
                    />

                    {errors.isActive &&
                      <Form.Control.Feedback type="invalid">
                        {errors.isActive?.message}
                      </Form.Control.Feedback>}

                    <Form.Text className="fs-7 text-muted">
                      Only active Supplier can have logins assigned to them. If
                      a Supplier is flagged as inactive, their associated logins
                      will no longer be able to access the Control Panel.
                    </Form.Text>
                  </Form.Group> */}

                </Col>
              </Row>
            </Container>
          </Col>

          {/* Manage Suppliers sidebar */}
          <Col lg={4}>
            <div className="bg-white p-3 mb-2">
              <Button
                className="w-100"
                type="submit"
                variant="primary"
              >
                Add Supplier
              </Button>
            </div>
          </Col>
        </Row>

      </Form>
    </>
  );
};
