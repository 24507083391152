import React from 'react';
import { useCookies } from 'react-cookie';
import { useIdleTimer } from 'react-idle-timer';
import { Routes, Route } from "react-router-dom";

// Routes
import { Layout as AppsLayout } from './features/apps/routes/Layout';
import { List as AppsList } from './features/apps/routes/List';

import { EditExploreListing } from
  './features/manage-explore/routes/EditExploreListing';
import { Layout as ExploreLayout } from
  './features/manage-explore/routes/Layout';
import { List as ExploreList } from './features/manage-explore/routes/List';
import { NewestOffers as ExploreNewestOffers } from
  './features/manage-explore/routes/NewestOffers';
import { EditTopLevelCategories as ExploreTopLevelCategories } from
  './features/manage-explore/routes/EditTopLevelCategories';
import { EditFilters as ExploreFilters } from
  './features/manage-explore/routes/EditFilters';
import { EditPriceRangeFilter as ExplorePriceRangeFilter } from
  './features/manage-explore/routes/EditPriceRangeFilter';

import { Users } from './features/users/routes/Users';

import { Enquiry } from './features/enquiries/routes/Enquiry';
import { Layout as EnquiriesLayout } from './features/enquiries/routes/Layout';
import { List as EnquiriesList } from './features/enquiries/routes/List';

import { MyListing } from './features/myListings/routes/MyListing';
import { Layout as MyListingLayout } from
  './features/myListings/routes/Layout';
import { List as MyListingList } from './features/myListings/routes/List';
import { FlashDeals as MyListingsFlashDeals } from
  './features/myListings/routes/FlashDeals';
import { SetUpFlashDealForm } from
  './features/myListings/routes/SetUpFlashDealForm';
import { EditFlashDealForm } from
  './features/myListings/routes/EditFlashDealForm';

import { AddLoginForm } from
  './features/manageLogins/routes/AddLoginForm';
import { ManageLoginForm } from
  './features/manageLogins/routes/ManageLoginForm';
import { Layout as ManageLoginsLayout } from
  './features/manageLogins/routes/Layout';
import { List as ManageLoginsList } from './features/manageLogins/routes/List';

import { AddSupplierForm } from
  './features/manageSuppliers/routes/AddSupplierForm';
import { ManageSupplierForm } from
  './features/manageSuppliers/routes/ManageSupplierForm';
import { AddSupplierAgreementForm } from
  './features/manageSuppliers/routes/AddSupplierAgreementForm';
import { Layout as ManageSuppliersLayout } from
  './features/manageSuppliers/routes/Layout';
import { List as ManageSuppliersList } from
  './features/manageSuppliers/routes/List';

import { Layout as ModerateLayout } from './features/moderate/routes/Layout';
import { ModerateFlashDealsList } from
  './features/moderate/routes/ModerateFlashDealsList';
import { ModerateFlashDealForm } from
  './features/moderate/routes/ModerateFlashDealForm';

import { Login } from './features/auth/routes/Login';

// Nav
import { MainNavBar } from './lib/nav/MainNavBar';

export const App = () => {
  // Idle timer
  const onIdle = event => {
    console.log('IDLE - 10 minute timeout');
  };
  const onNonIdleAction = event => {
    console.log('NON-IDLE - TODO use Date.now() - getLastActiveTime().getTime() to check whether to prompt for idleness');
    console.log('NON-IDLE - TODO update cookie expiry');
  };
  // const { getLastActiveTime } = useIdleTimer({
  useIdleTimer({
    debounce: 2000,
    onAction: onNonIdleAction,
    onIdle,
    timeout: 60000
  });

  return (
    <div className="App">
      <MainNavBar />
      <Routes>
        <Route path="/" element={<AppsLayout />}>
          <Route index element={<AppsList />} />
        </Route>

        <Route path="manage-explore" element={<ExploreLayout />}>
          <Route index element={<ExploreList />} />
          {/* <Route path="newest-offers" element={<ExploreNewestOffers />} /> */}
          <Route path="categories" element={<ExploreTopLevelCategories />} />
          <Route path="filters" element={<ExploreFilters />} />
          <Route path="price-range-filter" element={<ExplorePriceRangeFilter />} />
        </Route>

        <Route path="manage-explore/:id" element={<EditExploreListing />} />

        {/* <Route path="users" element={<Users />} /> */}

        <Route path="enquiries" element={<EnquiriesLayout />}>
          <Route index element={<EnquiriesList />} />
          <Route path=":id" element={<Enquiry />} />
        </Route>

        <Route path="my-listings" element={<MyListingLayout />}>
          <Route index element={<MyListingList />} />
          <Route path="flash-deals" element={<MyListingsFlashDeals />} />
          <Route path="flash-deals/set-up" element={<SetUpFlashDealForm />} />
          <Route path="flash-deals/:id" element={<EditFlashDealForm />} />
          <Route path=":id" element={<MyListing />} />
        </Route>

        <Route path="manage-logins" element={<ManageLoginsLayout />}>
          <Route index element={<ManageLoginsList />} />
          <Route path="add" element={<AddLoginForm />} />
          <Route path=":id" element={<ManageLoginForm />} />
        </Route>

        <Route path="manage-suppliers" element={<ManageSuppliersLayout />}>
          <Route index element={<ManageSuppliersList />} />
          <Route path="add" element={<AddSupplierForm />} />
          <Route path=":id" element={<ManageSupplierForm />} />
          <Route path=":id/add-agreement" element={<AddSupplierAgreementForm />} />
        </Route>

        <Route path="moderate-flash-deals" element={<ModerateLayout />}>
          <Route index element={<ModerateFlashDealsList />} />
          <Route path=":id" element={<ModerateFlashDealForm />} />
        </Route>

        <Route path="login" element={<Login />} />
      </Routes>
    </div>
  );
};
