// Extract appIds from the separate allowed apps fields
export const getAppIdsFromFields = (data, key) => {
  if (!data || !key) {
    return null;
  }
  
  const appIds = [];
  const fieldKeys = Object.keys(data);
  for (let i = 0; i < fieldKeys.length; i++) {
    if (
      fieldKeys[i].indexOf(key) === 0 &&
      data[fieldKeys[i]] === true
    ) {
      appIds.push(fieldKeys[i].slice(key.length));
    }
  }

  return appIds.join(',');
};