import React from 'react';

import Stack from 'react-bootstrap/Stack';

// Get check or x icon
const Icon = ({ boolVal }) => boolVal === true ?
  <i className="bi-check-circle-fill text-success" /> :
  <i className="bi-x-circle-fill text-danger" />;

export const LoginListAccessSettings = ({ access }) => {
  return (
    <Stack
      direction="horizontal"
      gap="3"
    >
      <span>Enquiries <Icon boolVal={access.enquiries} /></span>
      <span>Listings <Icon boolVal={access.editListings} /></span>
      <span>Flash Deals <Icon boolVal={access.flashDeals} /></span>
      <span>Billing <Icon boolVal={access.supplierBilling} /></span>
    </Stack>
  );
};
