import React, { useEffect } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from "react-router-dom";

// Bootstrap
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';

import { loadPriceRange, submitPriceRange } from '../store/actions';

import { devLog } from '../../../lib/util/devLog';
import { split } from 'lodash';

// Prep default values
const getDefaultValues = (priceRange, defaultLang, langs = []) => {
  // Option names i18n
  let optionNamesArrays = [];

  // Price Range name i18n
  const names = Object.fromEntries(
    langs.map(lang => {
      let name = lang.code === defaultLang ? priceRange.name : '';
      if (priceRange.i18n && priceRange.i18n[lang.code]) {
        name = priceRange.i18n[lang.code]?.name || name;
      }
      return [`name_${lang.code}`, name];
    })
  );

  // Get price range options per language
  if (priceRange.options && priceRange.options.length > 0) {
    priceRange.options.forEach(option => {
      optionNamesArrays = optionNamesArrays.concat(langs.map(lang => {
        // Use original non-i18n text by default
        let oname = lang.code === defaultLang ? option?.name : '';

        // If we have have i18n, override the default
        if (option.i18n && option.i18n[lang.code]) {
          oname = option.i18n[lang.code].name || oname;
        }

        return [
          `optName_${option._id.toString()}_${lang.code}`,
          oname
        ];
      }));
    });
  }

  const optionNames = Object.fromEntries(optionNamesArrays);

  return priceRange ?
    {
      id: priceRange.id,
      ...names,
      ...optionNames
    } :
    {};
};

export const EditPriceRangeFilter = () => {
  const dispatch = useDispatch();

  const priceRange = useSelector(({ explore }) => explore.priceRange);
  const isLoadingPriceRange = useSelector(
    ({ explore }) => explore.isLoadingPriceRange
  );

  const isSubmitting = useSelector(
    ({ explore }) => explore.isSubmitting
  );

  const appLangs = useSelector(({ auth }) => auth.appLangs);

  const thisAppLangs = appLangs.filter(
    al => al.appId === '60a38eeb7a796735244f4092'
  );
  const { defaultLang, langs } = thisAppLangs.length > 0 ?
    thisAppLangs[0] : { defaultLang: null, lang: [] };

  let defaultValues = getDefaultValues(
    priceRange || {},
    defaultLang,
    langs
  );

  const {
    clearErrors,
    control,
    handleSubmit,
    reset,
    setError,
    setValue,
    watch,
    formState: { errors, isSubmitSuccessful }
  } = useForm({
    defaultValues
  });

  useEffect(() => {
    if (!priceRange && !isLoadingPriceRange) {
      dispatch(loadPriceRange());
    }
  }, [priceRange]);

  // Reset the form on priceRange load
  useEffect(() => {
    if (priceRange) {
      reset(defaultValues);
    }
  }, [priceRange, reset]);

  if (isLoadingPriceRange === true || !priceRange) {
    return null;
  }

  // Event handlers

  // With have onSubmit and onSubmitForm below to allow manual clearErrors()
  // as react-hook-form requires manual clearing for general form errors.
  const onSubmit = formData => {
    if (isSubmitting) {
      return;
    }

    const otherData = {};
    const prData = {
      i18n: {},
      opts: {}
    };

    for (const [key, value] of Object.entries(formData)) {
      const splitKey = key.split('_');
      switch (true) {
        // Price range name translations
        case splitKey[0] === 'name':
          // Add lang ([1]) and value
          prData.i18n[splitKey[1]] = { name: value };
          break;

        // Option name translations
        case splitKey[0] === 'optName':
          // Create entry for option ([1])
          if (!prData.opts[splitKey[1]]) {
            prData.opts[splitKey[1]] = {
              i18n: {}
            };
          }

          // Add lang ([2]) and value
          prData.opts[splitKey[1]].i18n[splitKey[2]] = { name: value };
          break;

        default:
          otherData[key] = value;
      }
    }

    return dispatch(submitPriceRange({
      data: prData,
      ...otherData
    }))
      .unwrap()
      .then(({ data }) => {
        reset(getDefaultValues(
          data || null,
          defaultLang,
          langs
        ));
      })
      .catch(err => {
        if (err && err?.code === 401) {
          return dispatch(logOut());
        }
        else {
          setError(
            err.meta && err.meta.field ? err.meta.field : 'form',
            {
              type: 'server',
              message: err.error || err.toString()
            }
          );
        }
      });
  };
  const onSubmitForm = e => {
    clearErrors();
    return handleSubmit(onSubmit)(e);
  };

  // / Event Handlers

  // Fragment render fns

  // Render price range
  const renderPriceRange = priceRange => {
    return (
      <Form.Group
        className="bg-white mb-3 p-3"
        key={`tlct_${priceRange.id}`}
      >
        <h6 className="text-muted text-uppercase">Price Range Name</h6>
        <Container className="mb-3 px-0">
          <Row>
            {langs.map((lang, i) => {
              return <Col key={`prnmlng_${lang.code}`}>
                <span className="fs-7 text-muted text-uppercase">{lang.name}</span>

                <Controller
                  defaultValue=""
                  control={control}
                  name={`name_${lang.code}`}
                  render={({
                    field: { onBlur, onChange, ref, value },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState
                  }) => {
                    return <Form.Control
                      onBlur={onBlur}
                      onChange={onChange}
                      isInvalid={error}
                      placeholder="eg. Cuisine"
                      ref={ref}
                      value={value}
                      dir={lang.isRTL ? 'rtl' : 'ltr'}
                    />
                  }}
                />

                {errors[`name_${lang.code}`] &&
                  <Form.Control.Feedback type="invalid">
                    {errors[`name_${lang.code}`]?.message}
                  </Form.Control.Feedback>}

              </Col>
            })}
            <Form.Text className="fs-7 text-muted">
              A short one or two word name for price range in this app.
            </Form.Text>
          </Row>
        </Container>

        <h6 className="text-muted text-uppercase">Options</h6>
        {priceRange.options.map(option => {
          const keyPrefix = `optnmlng_${option._id.toString()}`;
          const fieldNamePrefix = `optName_${option._id.toString()}`;
          return (
            <Container
              className="px-0"
              key={keyPrefix}
            >
              <Row>
                {langs.map((lang, i) => <Col key={`${keyPrefix}_${lang.code}`}>
                  <span className="fs-7 text-muted text-uppercase">{lang.name}</span>

                  <Controller
                    defaultValue=""
                    control={control}
                    name={`${fieldNamePrefix}_${lang.code}`}
                    render={({
                      field: { onBlur, onChange, ref, value },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState
                    }) => (<Form.Control
                      className="flex-grow"
                      onBlur={onBlur}
                      onChange={onChange}
                      isInvalid={error}
                      ref={ref}
                      value={value}
                      dir={lang.isRTL ? 'rtl' : 'ltr'}
                    />)}
                  />

                  {errors[`${fieldNamePrefix}_${lang.code}`] &&
                    <Form.Control.Feedback type="invalid">
                      {errors[`${fieldNamePrefix}_${lang.code}`]?.message}
                    </Form.Control.Feedback>}
                </Col>)}
              </Row>
            </Container>
          );
        })}
      </Form.Group>
    );
  };

  // Render top-level category association
  const renderTopLevelCategory = cats => {
    if (!cats) {
      return <span>None</span>
    }

    const text = cats.map(c => c.name).join(', ');
    return <span>{text}</span>;
  };

  // / Fragment render fns

  return (
    <>
      <h1 className="mb-4">Edit Price Range</h1>

      <p>
        This is a fixed Explore Filter. You can specify the name you want it to
        appear as in this app, eg. "Price Range", "Budget", and the filter
        options which appear under it.
      </p>

      <hr />

      {isSubmitSuccessful && <Alert variant="success">
        Changes saved!
      </Alert>}

      {errors.form && <Alert variant="danger">
        {errors.form?.message}
      </Alert>}

      <Form onSubmit={onSubmitForm}>
        <Row className="mb-3">

          {/* Price Range */}
          <Col className="mb-3" lg={8} sm={12}>
            {renderPriceRange(priceRange)}
          </Col>

          {/* Sidebar */}
          <Col lg={4}>
            <div className="bg-white p-3 mb-2">
              <Button
                className="w-100"
                type="submit"
                variant="primary"
              >
                Save Changes
              </Button>
            </div>
          </Col>

        </Row>
      </Form>
    </>
  );
};
