'use strict';

import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiGet, apiPost } from '../../../lib/util/api';

import { suppliersPerPage } from '../../../../common/config';

// Load suppliers
export const loadSuppliers = createAsyncThunk(
  'manageSuppliers/loadPage',
  async (opts = {}, { getState, rejectWithValue }) => {
    let { page = 0 } = opts;

    try {
      // Get filters
      const { access, isTWMEAdmin, isSuperAdmin } = getState().auth;
      const isAdmin = (isTWMEAdmin || isSuperAdmin) || false;
      const { appsForFilter } = getState().manageSuppliers;
      const selectedApps = appsForFilter
        .filter(app => app.active === true);

      // Fail out if there are no apps to select - ie. no access to apps
      if (appsForFilter.length < 1) {
        throw { code: 401 };
      }

      // // Fail if no apps are selected (and not a global admin)
      // if (selectedApps.length < 1 && isAdmin !== true) {
      //   throw ({
      //     data: [],
      //     meta: {}
      //   });
      // }

      // Build request params      
      const params = {
        page,
        perPage: suppliersPerPage
      };

      if (selectedApps.length > 0) {
        params.appIds = selectedApps.map(app => app.id).join(',');
      }

      // Send the request
      const response = await apiGet('/cp-api/manage-suppliers', { params });

      if (response.status === 200) {
        return response.data;
      }
      throw { code: response.status };
    }
    catch (err) {
      return rejectWithValue(err);
    }
  }
);

// Load a selected supplier
export const loadSupplier = createAsyncThunk(
  'manageSuppliers/loadSelected',
  async (opts = {}, { rejectWithValue }) => {
    let { id } = opts;

    try {
      // Send the request
      const response = await apiGet(`/cp-api/manage-suppliers/${id}`);
      return response.data;
    }
    catch (err) {
      throw rejectWithValue(err);
    }
  }
);

// Submit add new supplier
export const submitAddSupplier = createAsyncThunk(
  'manageSuppliers/submitAdd',
  async (opts = {}, { getState, requestId, rejectWithValue }) => {
    let { appIds, email, name } = opts;

    const { currentRequestId, isSubmitting } = getState().manageSuppliers;
    if (!isSubmitting || requestId !== currentRequestId) {
      return;
    }

    try {
      const response = await apiPost(
        '/cp-api/manage-suppliers/add',
        { data: { appIds, email, name } }
      );

      return response.data;
    }
    catch (err) {
      throw rejectWithValue(err);
    }
  }
);

// Submit supplier changes
export const submitSupplier = createAsyncThunk(
  'manageSuppliers/submitEdit',
  async (opts = {}, { getState, requestId, rejectWithValue }) => {
    let { appIds, isActive, email, name, id } = opts;

    const { currentRequestId, isSubmitting } = getState().manageSuppliers;
    if (!isSubmitting || requestId !== currentRequestId) {
      return;
    }

    try {
      const response = await apiPost(
        `/cp-api/manage-suppliers/${id}`,
        { data: { appIds, isActive, email, name } }
      );

      return response.data;
    }
    catch (err) {
      throw rejectWithValue(err);
    }
  }
);

// Submit add new supplier agreement
export const submitAddSupplierAgreement = createAsyncThunk(
  'manageSuppliers/submitAddAgreement',
  async (opts = {}, { getState, requestId, rejectWithValue }) => {
    let {
      appIds,
      description,
      durationAmount,
      durationType,
      starts,
      ends,
      priceCurrency,
      priceAmount,
      priceDisplay
    } = opts;

    const {
      currentRequestId,
      isSubmitting,
      selectedSupplierId
    } = getState().manageSuppliers;

    if (
      !isSubmitting ||
      requestId !== currentRequestId ||
      !selectedSupplierId
    ) {
      return;
    }

    try {
      const response = await apiPost(
        `/cp-api/manage-suppliers/${selectedSupplierId}/add-agreement`,
        {
          data: {
            appIds,
            // isActive,
            description,
            durationAmount,
            durationType,
            starts,
            ends,
            priceCurrency,
            priceAmount,
            priceDisplay
          }
        }
      );

      if (!response.data?.success) {
        throw "Add Agreement failed with an unknown error";
      }
      
      return response.data;
    }
    catch (err) {
      throw rejectWithValue(err);
    }
  }
);
