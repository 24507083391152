import React, { useEffect } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from "react-router-dom";

// Bootstrap
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';

import { loadCategories, submitCategories } from '../store/actions';

import { devLog } from '../../../lib/util/devLog';
import { split } from 'lodash';
// Prep default values
const getDefaultValues = (categories, defaultLang, langs = []) => {
  // Category and subcategory names i18n
  let catNamesArrays = [];
  let scatNamesArrays = [];

  if (categories.length > 0) {
    // Loop each category
    categories.forEach(category => {
      // Get an array of category names per language for this category
      catNamesArrays = catNamesArrays.concat(langs.map(lang => {
        // Use original non-i18n text by default
        let name = lang.code === defaultLang ? category?.name : '';

        // If we have have i18n, override the default
        if (category.i18n && category.i18n[lang.code]) {
          name = category.i18n[lang.code].name || name;
        }

        // Do the subcategories while we're here
        category.subCategories.forEach(subCategory => {
          scatNamesArrays = scatNamesArrays.concat(langs.map(lang => {
            // Use original non-i18n text by default
            let sname = lang.code === defaultLang ? subCategory?.name : '';

            // If we have have i18n, override the default
            if (subCategory.i18n && subCategory.i18n[lang.code]) {
              sname = subCategory.i18n[lang.code].name || sname;
            }

            return [
              `scatName_${category.id}_${subCategory._id.toString()}_${lang.code}`,
              sname
            ];
          }));
        });

        return [`catName_${category.id}_${lang.code}`, name];
      }));
    });
  }

  const catNames = Object.fromEntries(catNamesArrays);
  const scatNames = Object.fromEntries(scatNamesArrays);

  return categories.length > 0 ?
    {
      ...catNames,
      ...scatNames
    } :
    {};
};

export const EditTopLevelCategories = () => {
  const dispatch = useDispatch();

  const categories = useSelector(({ explore }) => explore.categories);
  const isLoadingCategories = useSelector(
    ({ explore }) => explore.isLoadingCategories
  );

  const isSubmitting = useSelector(
    ({ explore }) => explore.isSubmitting
  );

  const appLangs = useSelector(({ auth }) => auth.appLangs);

  const thisAppLangs = appLangs.filter(
    al => al.appId === '60a38eeb7a796735244f4092'
  );
  const { defaultLang, langs } = thisAppLangs.length > 0 ?
    thisAppLangs[0] : { defaultLang: null, lang: [] };

  let defaultValues = getDefaultValues(
    categories || [],
    defaultLang,
    langs
  );

  const {
    clearErrors,
    control,
    handleSubmit,
    reset,
    setError,
    setValue,
    watch,
    formState: { errors, isSubmitSuccessful }
  } = useForm({
    defaultValues
  });

  useEffect(() => {
    if (!categories && !isLoadingCategories) {
      dispatch(loadCategories());
    }
  }, [categories]);

  // Reset the form on categories load
  useEffect(() => {
    if (categories) {
      reset(defaultValues);
    }
  }, [categories, reset]);

  if (isLoadingCategories === true || !categories) {
    return null;
  }

  // Event handlers

  // With have onSubmit and onSubmitForm below to allow manual clearErrors()
  // as react-hook-form requires manual clearing for general form errors.
  const onSubmit = formData => {
    if (isSubmitting) {
      return;
    }

    const submitData = {};

    for (const [key, value] of Object.entries(formData)) {
      const splitKey = key.split('_');
      switch (true) {
        // Category name translations
        case splitKey[0] === 'catName':
          // Create entry for category ([1])
          if (!submitData[splitKey[1]]) {
            submitData[splitKey[1]] = {
              i18n: {},
              subs: {}
            };
          }

          // Add lang ([2]) and value
          submitData[splitKey[1]].i18n[splitKey[2]] = { name: value };
          break;

        // Sub-category name translations
        case splitKey[0] === 'scatName':
          // Create entry for category ([1])
          if (!submitData[splitKey[1]]) {
            submitData[splitKey[1]] = {
              i18n: {},
              subs: {}
            };
          }

          // Create entry for sub-category ([2])
          if (!submitData[splitKey[1]].subs[splitKey[2]]) {
            submitData[splitKey[1]].subs[splitKey[2]] = {
              i18n: {}
            };
          }

          // Add lang ([3]) and value
          submitData[splitKey[1]]
            .subs[splitKey[2]].i18n[splitKey[3]] = { name: value };
          break;

        default:
          submitData[key] = value;
      }
    }

    return dispatch(submitCategories({
      data: submitData
    }))
      .unwrap()
      .then(({ data }) => {
        reset(getDefaultValues(
          data.cats || null,
          defaultLang,
          langs
        ));
      })
      .catch(err => {
        if (err && err?.code === 401) {
          return dispatch(logOut());
        }
        else {
          setError(
            err.meta && err.meta.field ? err.meta.field : 'form',
            {
              type: 'server',
              message: err.error || err.toString()
            }
          );
        }
      });
  };
  const onSubmitForm = e => {
    clearErrors();
    return handleSubmit(onSubmit)(e);
  };

  // / Event Handlers

  // Fragment render fns

  const renderCategory = category => {
    return (
      <Form.Group
        className="bg-white mb-3 p-3"
        key={`tlct_${category.id}`}
      >
        <h6 className="text-muted text-uppercase">Category Name</h6>
        <Container className="mb-3 px-0">
          <Row>
            {langs.map((lang, i) => {
              return <Col key={`ctnmlng_${category.id}_${lang.code}`}>
                <span className="fs-7 text-muted text-uppercase">{lang.name}</span>

                <Controller
                  defaultValue=""
                  control={control}
                  name={`catName_${category.id}_${lang.code}`}
                  render={({
                    field: { onBlur, onChange, ref, value },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState
                  }) => {
                    return <Form.Control
                      onBlur={onBlur}
                      onChange={onChange}
                      isInvalid={error}
                      placeholder="eg. Accommodation"
                      ref={ref}
                      value={value}
                      dir={lang.isRTL ? 'rtl' : 'ltr'}
                    />
                  }}
                />

                {errors[`catName_${category.id}_${lang.code}`] &&
                  <Form.Control.Feedback type="invalid">
                    {errors[`catName_${category.id}_${lang.code}`]?.message}
                  </Form.Control.Feedback>}

              </Col>
            })}
            <Form.Text className="fs-7 text-muted">
              A short name for this category to appear in a Top-level Category tab.
            </Form.Text>
          </Row>
        </Container>

        <h6 className="text-muted text-uppercase">Sub-category Names</h6>
        {category.subCategories.map(subCategory => {
          const keyPrefix = `sctnmlng_${category.id}_${subCategory._id.toString()}`;
          return (
            <Container
              className="px-0"
              key={keyPrefix}
            >
              <Row>
                {langs.map((lang, i) => <Col key={`${keyPrefix}_${lang.code}`}>
                  <span className="fs-7 text-muted text-uppercase">{lang.name}</span>

                  <Controller
                    defaultValue=""
                    control={control}
                    name={`scatName_${category.id}_${subCategory._id.toString()}_${lang.code}`}
                    render={({
                      field: { onBlur, onChange, ref, value },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState
                    }) => (<Form.Control
                      className="flex-grow"
                      onBlur={onBlur}
                      onChange={onChange}
                      isInvalid={error}
                      ref={ref}
                      value={value}
                      dir={lang.isRTL ? 'rtl' : 'ltr'}
                    />)}
                  />

                  {errors[`scatName_${category.id}_${subCategory._id.toString()}_${lang.code}`] &&
                    <Form.Control.Feedback type="invalid">
                      {errors[`scatName_${category.id}_${subCategory._id.toString()}_${lang.code}`]?.message}
                    </Form.Control.Feedback>}
                </Col>)}
              </Row>
            </Container>
          );
        })}
      </Form.Group>
    );
  };

  // / Fragment render fns

  return (
    <>
      <h1 className="mb-4">Edit Top-Level Categories</h1>

      <p>
        You can edit the names of Top-level Categories, and set up
        Sub-categories which will be shown as the first filter under each
        Top-level Category.
      </p>

      <hr />

      {isSubmitSuccessful && <Alert variant="success">
        Changes saved!
      </Alert>}

      {errors.form && <Alert variant="danger">
        {errors.form?.message}
      </Alert>}

      <Form onSubmit={onSubmitForm}>
        <Row className="mb-3">

          {/* Categories */}
          <Col className="mb-3" lg={8} sm={12}>
            {categories.map(category => renderCategory(category))}
          </Col>

          {/* Sidebar */}
          <Col lg={4}>
            <div className="bg-white p-3 mb-2">
              <Button
                className="w-100"
                type="submit"
                variant="primary"
              >
                Save Changes
              </Button>
            </div>
          </Col>

        </Row>
      </Form>
    </>
  );
};
