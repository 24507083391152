import React, { useEffect } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from "react-router-dom";

// Bootstrap
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';

import { loadFilters, submitFilters } from '../store/actions';

import { devLog } from '../../../lib/util/devLog';
import { split } from 'lodash';
// Prep default values
const getDefaultValues = (filters, defaultLang, langs = []) => {
  // Filter and option names i18n
  let filterNamesArrays = [];
  let optionNamesArrays = [];

  if (filters.length > 0) {
    // Loop each filter
    filters.forEach(filter => {
      // Get an array of filter names per language for this filter
      filterNamesArrays = filterNamesArrays.concat(langs.map(lang => {
        // Use original non-i18n text by default
        let name = lang.code === defaultLang ? filter?.name : '';

        // If we have have i18n, override the default
        if (filter.i18n && filter.i18n[lang.code]) {
          name = filter.i18n[lang.code].name || name;
        }

        // Do the filter options while we're here
        filter.options.forEach(option => {
          optionNamesArrays = optionNamesArrays.concat(langs.map(lang => {
            // Use original non-i18n text by default
            let oname = lang.code === defaultLang ? option?.name : '';

            // If we have have i18n, override the default
            if (option.i18n && option.i18n[lang.code]) {
              oname = option.i18n[lang.code].name || oname;
            }

            return [
              `optName_${filter.id}_${option._id.toString()}_${lang.code}`,
              oname
            ];
          }));
        });

        return [`filName_${filter.id}_${lang.code}`, name];
      }));
    });
  }

  const filterNames = Object.fromEntries(filterNamesArrays);
  const optionNames = Object.fromEntries(optionNamesArrays);

  return filters.length > 0 ?
    {
      ...filterNames,
      ...optionNames
    } :
    {};
};

export const EditFilters = () => {
  const dispatch = useDispatch();

  const filters = useSelector(({ explore }) => explore.filters);
  const isLoadingFilters = useSelector(
    ({ explore }) => explore.isLoadingFilters
  );

  const isSubmitting = useSelector(
    ({ explore }) => explore.isSubmitting
  );

  const appLangs = useSelector(({ auth }) => auth.appLangs);

  const thisAppLangs = appLangs.filter(
    al => al.appId === '60a38eeb7a796735244f4092'
  );
  const { defaultLang, langs } = thisAppLangs.length > 0 ?
    thisAppLangs[0] : { defaultLang: null, lang: [] };

  let defaultValues = getDefaultValues(
    filters || [],
    defaultLang,
    langs
  );

  const {
    clearErrors,
    control,
    handleSubmit,
    reset,
    setError,
    setValue,
    watch,
    formState: { errors, isSubmitSuccessful }
  } = useForm({
    defaultValues
  });

  useEffect(() => {
    if (!filters && !isLoadingFilters) {
      dispatch(loadFilters());
    }
  }, [filters]);

  // Reset the form on filters load
  useEffect(() => {
    if (filters) {
      reset(defaultValues);
    }
  }, [filters, reset]);

  if (isLoadingFilters === true || !filters) {
    return null;
  }

  // Event handlers

  // With have onSubmit and onSubmitForm below to allow manual clearErrors()
  // as react-hook-form requires manual clearing for general form errors.
  const onSubmit = formData => {
    if (isSubmitting) {
      return;
    }

    const submitData = {};

    for (const [key, value] of Object.entries(formData)) {
      const splitKey = key.split('_');
      switch (true) {
        // Filter name translations
        case splitKey[0] === 'filName':
          // Create entry for filter ([1])
          if (!submitData[splitKey[1]]) {
            submitData[splitKey[1]] = {
              i18n: {},
              opts: {}
            };
          }

          // Add lang ([2]) and value
          submitData[splitKey[1]].i18n[splitKey[2]] = { name: value };
          break;

        // Option name translations
        case splitKey[0] === 'optName':
          // Create entry for filter ([1])
          if (!submitData[splitKey[1]]) {
            submitData[splitKey[1]] = {
              i18n: {},
              opts: {}
            };
          }

          // Create entry for option ([2])
          if (!submitData[splitKey[1]].opts[splitKey[2]]) {
            submitData[splitKey[1]].opts[splitKey[2]] = {
              i18n: {}
            };
          }

          // Add lang ([3]) and value
          submitData[splitKey[1]]
            .opts[splitKey[2]].i18n[splitKey[3]] = { name: value };
          break;

        default:
          submitData[key] = value;
      }
    }

    return dispatch(submitFilters({
      data: submitData
    }))
      .unwrap()
      .then(({ data }) => {
        reset(getDefaultValues(
          data.filters || null,
          defaultLang,
          langs
        ));
      })
      .catch(err => {
        if (err && err?.code === 401) {
          return dispatch(logOut());
        }
        else {
          setError(
            err.meta && err.meta.field ? err.meta.field : 'form',
            {
              type: 'server',
              message: err.error || err.toString()
            }
          );
        }
      });
  };
  const onSubmitForm = e => {
    clearErrors();
    return handleSubmit(onSubmit)(e);
  };

  // / Event Handlers

  // Fragment render fns

  // Render filter
  const renderFilter = filter => {
    return (
      <Form.Group
        className="bg-white mb-3 p-3"
        key={`tlct_${filter.id}`}
      >
        <h6 className="text-muted text-uppercase">Top-Level Category</h6>
        <Container className="mb-3 px-0">
          <Row><Col>
            {renderTopLevelCategory(filter?.expCategories)}
          </Col></Row>
        </Container>

        <h6 className="text-muted text-uppercase">Filter Name</h6>
        <Container className="mb-3 px-0">
          <Row>
            {langs.map((lang, i) => {
              return <Col key={`flnmlng_${filter.id}_${lang.code}`}>
                <span className="fs-7 text-muted text-uppercase">{lang.name}</span>

                <Controller
                  defaultValue=""
                  control={control}
                  name={`filName_${filter.id}_${lang.code}`}
                  render={({
                    field: { onBlur, onChange, ref, value },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState
                  }) => {
                    return <Form.Control
                      onBlur={onBlur}
                      onChange={onChange}
                      isInvalid={error}
                      placeholder="eg. Cuisine"
                      ref={ref}
                      value={value}
                      dir={lang.isRTL ? 'rtl' : 'ltr'}
                    />
                  }}
                />

                {errors[`filName_${filter.id}_${lang.code}`] &&
                  <Form.Control.Feedback type="invalid">
                    {errors[`filName_${filter.id}_${lang.code}`]?.message}
                  </Form.Control.Feedback>}

              </Col>
            })}
            <Form.Text className="fs-7 text-muted">
              A short name for this filter to appear in the Filters screen.
            </Form.Text>
          </Row>
        </Container>

        <h6 className="text-muted text-uppercase">Filter Options</h6>
        {filter.options.map(option => {
          const keyPrefix = `optnmlng_${filter.id}_${option._id.toString()}`;
          return (
            <Container
              className="px-0"
              key={keyPrefix}
            >
              <Row>
                {langs.map((lang, i) => <Col key={`${keyPrefix}_${lang.code}`}>
                  <span className="fs-7 text-muted text-uppercase">{lang.name}</span>

                  <Controller
                    defaultValue=""
                    control={control}
                    name={`optName_${filter.id}_${option._id.toString()}_${lang.code}`}
                    render={({
                      field: { onBlur, onChange, ref, value },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState
                    }) => (<Form.Control
                      className="flex-grow"
                      onBlur={onBlur}
                      onChange={onChange}
                      isInvalid={error}
                      ref={ref}
                      value={value}
                      dir={lang.isRTL ? 'rtl' : 'ltr'}
                    />)}
                  />

                  {errors[`optName_${filter.id}_${option._id.toString()}_${lang.code}`] &&
                    <Form.Control.Feedback type="invalid">
                      {errors[`optName_${filter.id}_${option._id.toString()}_${lang.code}`]?.message}
                    </Form.Control.Feedback>}
                </Col>)}
              </Row>
            </Container>
          );
        })}
      </Form.Group>
    );
  };

  // Render top-level category association
  const renderTopLevelCategory = cats => {
    if (!cats) {
      return <span>None</span>
    }

    const text = cats.map(c => c.name).join(', ');
    return <span>{text}</span>;
  };

  // / Fragment render fns

  return (
    <>
      <h1 className="mb-4">Edit Filters</h1>

      <p>
        You can edit the names of Filters, and set up Filter Options
        which will be shown under each one.
      </p>

      <hr />

      {isSubmitSuccessful && <Alert variant="success">
        Changes saved!
      </Alert>}

      {errors.form && <Alert variant="danger">
        {errors.form?.message}
      </Alert>}

      <Form onSubmit={onSubmitForm}>
        <Row className="mb-3">

          {/* Filters */}
          <Col className="mb-3" lg={8} sm={12}>
            {filters.map(filter => renderFilter(filter))}
          </Col>

          {/* Sidebar */}
          <Col lg={4}>
            <div className="bg-white p-3 mb-2">
              <Button
                className="w-100"
                type="submit"
                variant="primary"
              >
                Save Changes
              </Button>
            </div>
          </Col>

        </Row>
      </Form>
    </>
  );
};
