import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import Datetime from 'react-datetime';
import Countdown from 'react-countdown';
import moment from 'moment-timezone';

// Bootstrap
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import Table from 'react-bootstrap/Table';

// Components
import { EmbeddedTable }
  from '../../../lib/components/EmbeddedTable';

// Actions
import {
  submitNewFlashDeal
} from '../store/actions';
import { logOut } from '../../auth/store/actions';
import {
  // addAddFormExploreItem,
  // removeAddFormExploreItem,

  // addExploreItem,
  // removeExploreItem,

  // clearExploreSearchResults
} from '../store/slice';

// Util
import { devLog } from '../../../lib/util/devLog';

import { defaultAccess } from '../../../../common/config';

// Show a list of errors  
const showErrorsList = errors => {
  return (
    <ul className="mb-0">
      {errors.map((error, index) => <li key={`erlst_${index}`}>{error}</li>)}
    </ul>
  );
};

// Prep default values
const getDefaultValues = (exploreId, catId, flashDeal) => {
  let values = {};

  if (exploreId) {
    values.exploreId = exploreId;
  }

  if (catId) {
    values.catId = catId;
  }

  return flashDeal ?
    Object.assign(values, {
      summary: flashDeal.summary,
      details: flashDeal.details,
      from: flashDeal.from,
      to: flashDeal.to,
      code: flashDeal.code,
      status: flashDeal.status,
      history: flashDeal.history
    }) :
    values;
};


export const SetUpFlashDealForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const exploreSearchRef = useRef();

  const flashDealListingId = useSelector(
    ({ myListings }) => myListings.flashDealListingId
  );
  const flashDealListingName = useSelector(
    ({ myListings }) => myListings.flashDealListingName
  );

  const isSubmitting = useSelector(
    ({ myListings }) => myListings.isSubmitting
  );

  // Set login exploreIds and items (search results for display)
  const [exploreIds, setExploreIds] = useState([]);
  const [exploreItems, setExploreItems] = useState([]);

  // Earliest date/time for validation
  const earliestDateTime = moment()
    .add(1, 'minute')
    .add(24, 'hours');
  // Latest date/time for placeholder only (as real latest is From + 7 days)
  const latestDateTime = moment()
    .add(24, 'hours')
    .add(7, 'days');

  const flashDealDefaults = {
    from: '',
    to: '',
    summary: '',
    details: '',
    code: '********'
  };

  // Get form defaults
  let defaultValues = getDefaultValues(null, null, flashDealDefaults);
  const {
    clearErrors,
    control,
    // getValues,
    handleSubmit,
    reset,
    setError,
    // setValue,
    // watch,
    formState: { errors, isSubmitSuccessful }
  } = useForm({
    defaultValues
  });

  // // Reset the form on login load
  // useEffect(() => {
  //   console.log('FETCH DEFAULTS?');
  //   if (!isFetchingDefaults) {
  //     console.log('YES, FETCH DEFAULTS');
  //     dispatch(getAddLoginFormDefaults());
  //   }
  // }, []);

  // Checking we have the Flash Deal listing ID
  useEffect(() => {
    if (!flashDealListingId) {
      navigate('/my-listings/');
    }
  }, [flashDealListingId]);

  // Event handlers

  // With have onSubmit and onSubmitForm below to allow manual clearErrors()
  // as react-hook-form requires manual clearing for general form errors.
  const onSubmit = data => {
    if (isSubmitting) {
      // console.log('already submitting');
      return;
    }

    // Extract data from formdata object, and add processed fields
    const submitData = (({ from, to, details, summary }) =>
      ({ from, to, details, summary }))(data);

    // Last minute checks or things that can't validate automatically...
    const errors = [];

    // Check to date isn't before from date
    if (submitData.to.isBefore(submitData.from)) {
      errors.push('The End Date/Time cannot be before the Start Date/Time.');
    }

    // Check from date is at least the earliest allowed
    if (submitData.from.isBefore(earliestDateTime)) {
      errors.push('The Start Date/Time is too early. Please allow at least ' +
        '24 hours for approval.');
    }

    // Check to date is no later than 7 days past the from date
    if (submitData.to.isAfter(moment(submitData.from).add(7, 'days'))) {
      errors.push('The End Date/Time is too late. The maximum length of ' +
        'a Flash Deal is 7 days.');
    }

    if (errors.length > 0) {
      setError('form', {
        message: showErrorsList(errors)
      });
    }

    submitData.from = submitData.from.toISOString();
    submitData.to = submitData.to.toISOString();

    // console.log('SENDING TO ACTION', submitData);

    return dispatch(submitNewFlashDeal(submitData))
      .unwrap()
      .then(({ data = {} }) => {
        setTimeout(() => navigate('/my-listings'), 5000);
      })
      .catch(err => {
        if (err && err?.code === 401) {
          return dispatch(logOut());
        }
        else {
          // console.log('ERROR?', err);
          setError(
            err.meta && err.meta.field ? err.meta.field : 'form',
            {
              type: 'server',
              message: err.error || err.toString()
            }
          );
        }
      });
  };
  const onSubmitForm = e => {
    clearErrors();
    return handleSubmit(onSubmit, (err) => {
      // Catching submission errors
    })(e);
  };

  // / Event handlers

  return (
    <>
      <h1 className="mb-4">
        <Link className="text-muted" to="/my-listings">My Listings</Link>
        {' / Set Up Flash Deal'}
      </h1>

      <Form onSubmit={onSubmitForm}>

        {isSubmitSuccessful && <Alert variant="success">
          Flash Deal submitted for approval. <span className="text-muted">
            Back to My Listings in <Countdown
              date={Date.now() + 5000}
              renderer={({ seconds }) => <span>{seconds}s</span>}
            />
          </span>
        </Alert>}

        {errors.form && <Alert variant="danger">
          {errors.form?.message}
        </Alert>}

        <Row className="mb-3">
          {/* Login details */}
          <Col className="mb-3" lg={8} sm={12}>
            <Container className="bg-white p-3">
              {/* TOP ROW - LISTING NAME */}
              <Row className="mb-3">
                <Col className="mb-0">
                  <Form.Group>
                    <span className="fs-7 text-muted text-uppercase">
                      Setting up Flash Deal for this listing:
                    </span>

                    <Form.Control
                      disabled={true}
                      value={flashDealListingName}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>

                {/* LEFT COLUMN */}
                <Col className="mb-3" lg={6}>

                  {/* Start Date and Time */}
                  <Form.Group className="mb-3">
                    <span className="fs-7 text-muted text-uppercase">
                      Start Date and Time
                    </span>

                    <Controller
                      defaultValue=""
                      control={control}
                      name="from"
                      render={({
                        field: { onBlur, onChange, ref, value },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState
                      }) => (<Datetime
                        dateFormat="MMM Do YYYY"
                        inputProps={{
                          placeholder: 'eg. ' + earliestDateTime
                            .format('MMM Do YYYY HH:mm')
                        }}
                        isInvalid={error}
                        onChange={onChange}
                        ref={ref}
                        timeFormat="HH:mm"
                        timeConstraints={{
                          minutes: { min: 0, max: 59, step: 15 }
                        }}
                        value={value}
                      />)}
                      rules={{ required: true }}
                    />

                    {errors.from && <Form.Control.Feedback type="invalid">
                      {errors.from?.message}
                    </Form.Control.Feedback>}

                    <Form.Text className="fs-7 text-muted">
                      The date and time the Flash Deal should begin. It must be
                      at least 24 hours from the time of submission.
                    </Form.Text>
                  </Form.Group>

                  {/* End Date and Time */}
                  <Form.Group className="mb-3">
                    <span className="fs-7 text-muted text-uppercase">
                      End Date and Time
                    </span>

                    <Controller
                      defaultValue=""
                      control={control}
                      name="to"
                      render={({
                        field: { onBlur, onChange, ref, value },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState
                      }) => (<Datetime
                        dateFormat="MMM Do YYYY"
                        inputProps={{
                          placeholder: 'eg. ' + latestDateTime
                            .format('MMM Do YYYY HH:mm')
                        }}
                        isInvalid={error}
                        onChange={onChange}
                        ref={ref}
                        timeFormat="HH:mm"
                        timeConstraints={{
                          minutes: { min: 0, max: 59, step: 15 }
                        }}
                        value={value}
                      />)}
                      rules={{ required: true }}
                    />

                    {errors.to && <Form.Control.Feedback type="invalid">
                      {errors.to?.message}
                    </Form.Control.Feedback>}

                    <Form.Text className="fs-7 text-muted">
                      The date and time of the end of the Flash Deal. It must be
                      a maximum of 7 days (full 24 hour periods) from the Start
                      Date.
                    </Form.Text>
                  </Form.Group>

                  {/* Deal code */}
                  <Form.Group>
                    <span className="fs-7 text-muted text-uppercase">
                      Flash Deal Code
                    </span>

                    <Form.Control
                      disabled={true}
                      // isInvalid={error}
                      // onBlur={onBlur}
                      // onChange={onChange}
                      value="********"
                    />

                    <Form.Text className="fs-7 text-muted">
                      The Flash Deal's unique code. Users must unlock the app to
                      see this code, and must show it at the location to use the
                      offer. This random 8-character code will be generated
                      automatically when you submit this Flash Deal.
                    </Form.Text>
                  </Form.Group>

                </Col>

                {/* RIGHT COLUMN */}
                <Col lg={6}>

                  {/* Deal summary */}
                  <Form.Group className="mb-3">
                    <span className="fs-7 text-muted text-uppercase">
                      Short Summary
                    </span>

                    <Controller
                      defaultValue=""
                      control={control}
                      name="summary"
                      render={({
                        field: { onBlur, onChange, ref, value },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState
                      }) => (<Form.Control
                        isInvalid={error}
                        onBlur={onBlur}
                        onChange={onChange}
                        maxLength="50"
                        placeholder="eg. 10% off all double rooms"
                        ref={ref}
                        value={value}
                      />)}
                      rules={{
                        required: {
                          message: 'Please enter a short summary of the deal.',
                          value: true
                        }
                      }}
                    />

                    {errors.summary && <Form.Control.Feedback type="invalid">
                      {errors.summary?.message}
                    </Form.Control.Feedback>}

                    <Form.Text className="fs-7 text-muted">
                      A short summary of the deal. Maximum of 50 characters.
                    </Form.Text>
                  </Form.Group>

                  {/* Deal details */}
                  <Form.Group>
                    <span className="fs-7 text-muted text-uppercase">
                      Details
                    </span>

                    <Controller
                      defaultValue=""
                      control={control}
                      name="details"
                      render={({
                        field: { onBlur, onChange, ref, value },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState
                      }) => (<Form.Control
                        as="textarea"
                        isInvalid={error}
                        onBlur={onBlur}
                        onChange={onChange}
                        ref={ref}
                        rows="10"
                        value={value}
                      />)}
                      rules={{
                        required: {
                          message: 'Please enter full details of the deal.',
                          value: true
                        }
                      }}
                    />

                    {errors.details && <Form.Control.Feedback type="invalid">
                      {errors.details?.message}
                    </Form.Control.Feedback>}

                    <Form.Text className="fs-7 text-muted">
                      Full details of the deal, including any conditions,
                      exclusions or requirements.
                    </Form.Text>
                  </Form.Group>

                </Col>
              </Row>
            </Container>
          </Col>

          {/* Set Up FD sidebar */}
          <Col lg={4}>
            {/* <Stack gap={2}> */}
            <div className="bg-white p-3 mb-2">
              <Button
                className="w-100 mb-2"
                type="submit"
                variant="primary"
              >
                Submit For Approval
              </Button>
              <Form.Text className="fs-7 text-muted">
                All Flash Deals must be approved by a moderator. When you submit
                this Flash Deal, a moderator will be informed and they will
                check and approve your Flash Deal as soon as possible. If the
                content of the Flash Deal is rejected, a reason will be given by
                the moderator.
              </Form.Text>
            </div>
            {/* </Stack> */}
          </Col>
        </Row>

      </Form>
    </>
  );
};
