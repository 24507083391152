'use strict';

import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiGet, apiPost } from '../../../lib/util/api';

import { loginsPerPage } from '../../../../common/config';

// Load logins
export const loadLogins = createAsyncThunk(
  'manageLogins/loadPage',
  async (opts = {}, { getState, rejectWithValue }) => {
    let { page = 0 } = opts;

    try {
      // Get filters
      const { access, isTWMEAdmin, isSuperAdmin } = getState().auth;
      const isAdmin = (isTWMEAdmin || isSuperAdmin) || false;
      const { appsForFilter } = getState().manageLogins;
      const selectedApps = appsForFilter
        .filter(app => app.active === true);

      // Fail out if there are no apps to select - ie. no access to apps
      if (appsForFilter.length < 1) {
        throw { code: 401 };
      }

      // // Fail if no apps are selected (and not a global admin)
      // if (selectedApps.length < 1 && isAdmin !== true) {
      //   throw ({
      //     data: [],
      //     meta: {}
      //   });
      // }

      // Build request params      
      const params = {
        page,
        perPage: loginsPerPage
      };

      if (selectedApps.length > 0) {
        params.appIds = selectedApps.map(app => app.id).join(',');
      }

      // Send the request
      const response = await apiGet('/cp-api/manage-logins', { params });
      return response.data;
    }
    catch (err) {
      return rejectWithValue(err);
    }
  }
);

// // Fetch data needed for the Add Login Form
// export const getAddLoginFormDefaults = createAsyncThunk(
//   'manageLogins/getAddLoginFormDefaults',
//   async (opts = {}, { rejectWithValue }) => {
//     let { id } = opts;

//     try {
//       // Send the request
//       const response = await apiGet('/cp-api/manage-logins/add-login-defaults');
//       return response.data;
//     }
//     catch (err) {
//       throw rejectWithValue(err);
//     }
//   }
// );

// Load a selected login
export const loadLogin = createAsyncThunk(
  'manageLogins/loadSelected',
  async (opts = {}, { rejectWithValue }) => {
    let { id } = opts;

    try {
      // Send the request
      const response = await apiGet(`/cp-api/manage-logins/${id}`);
      return response.data;
    }
    catch (err) {
      throw rejectWithValue(err);
    }
  }
);

// Search Explore locations callback
const searchExploreCallback = async (opts = {}, { getState, requestId, rejectWithValue }) => {
  let { appIds = '', terms = '' } = opts;

  terms = terms.trim();

  if (terms.length === 0) {
    throw 'No search terms entered.';
  }

  const { currentSearchId, isSearching } = getState().manageLogins;
  if (!isSearching || requestId !== currentSearchId) {
    return;
  }

  const params = { data: { appIds, terms } };

  try {
    const response = await apiPost(
      `/cp-api/manage-logins/search-explore`,
      params
    );

    return response.data;
  }
  catch (err) {
    throw rejectWithValue(err);
  }
};

// Add Form search Explore locations
export const addFormSearchExplore = createAsyncThunk(
  'manageLogins/addFormSearchExplore',
  searchExploreCallback
);

// Edit Form search Explore locations
export const searchExplore = createAsyncThunk(
  'manageLogins/searchExplore',
  searchExploreCallback
);

// Search Suppliers callback
const searchSuppliersCallback = async (opts = {}, { getState, requestId, rejectWithValue }) => {
  let { appIds = '', terms = '' } = opts;

  terms = terms.trim();

  if (terms.length === 0) {
    throw 'No search terms entered.';
  }

  const { currentSearchId, isSearching } = getState().manageLogins;
  if (!isSearching || requestId !== currentSearchId) {
    return;
  }

  const params = { data: { appIds, terms } };

  try {
    const response = await apiPost(
      `/cp-api/manage-logins/search-suppliers`,
      params
    );

    return response.data;
  }
  catch (err) {
    throw rejectWithValue(err);
  }
};

// Add Form search Suppliers
export const addFormSearchSuppliers = createAsyncThunk(
  'manageLogins/addFormSearchSuppliers',
  searchSuppliersCallback
);

// Edit Form search Suppliers
export const searchSuppliers = createAsyncThunk(
  'manageLogins/searchSuppliers',
  searchSuppliersCallback
);

// Submit add new login
export const submitAddLogin = createAsyncThunk(
  'manageLogins/submitAdd',
  async (opts = {}, { getState, requestId, rejectWithValue }) => {
    let { access, appIds, email, exploreIds, name, supplierId } = opts;

    const { currentRequestId, isSubmitting } = getState().manageLogins;
    if (!isSubmitting || requestId !== currentRequestId) {
      return;
    }

    try {
      const response = await apiPost(
        '/cp-api/manage-logins/add',
        { data: { access, appIds, email, exploreIds, name, supplierId } }
      );

      if (!response.data.success){ //data?.id) {
        throw 'Unknown error adding login. Please try again.';
      }

      return response.data;
    }
    catch (err) {
      throw rejectWithValue(err);
    }
  }
);

// Submit login changes
export const submitLogin = createAsyncThunk(
  'manageLogins/submitEdit',
  async (opts = {}, { getState, requestId, rejectWithValue }) => {
    let { access, appIds, email, exploreIds, id, name, supplierId } = opts;

    const { currentRequestId, isSubmitting } = getState().manageLogins;
    if (!isSubmitting || requestId !== currentRequestId) {
      return;
    }

    try {
      const response = await apiPost(
        `/cp-api/manage-logins/${id}`,
        { data: { access, appIds, email, exploreIds, name, supplierId } }
      );

      return response.data;
    }
    catch (err) {
      throw rejectWithValue(err);
    }
  }
);

// Reset password
export const resetPassword = createAsyncThunk(
  'manageLogins/resetPwd',
  async (opts = {}, { getState, requestId, rejectWithValue }) => {
    const { id } = opts;

    if (!id) {
      throw 'Reset password failed due to no login ID.';
    }

    const {
      currentResetPasswordId,
      isResettingPassword
    } = getState().manageLogins;
    if (!isResettingPassword || requestId !== currentResetPasswordId) {
      return;
    }

    try {
      const response = await apiPost(
        '/cp-api/manage-logins/reset-password',
        { data: { id } }
      );
      return response.data;
    }
    catch (err) {
      throw rejectWithValue(err);
    }
  }
);
