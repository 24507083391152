'use strict';

import { createSlice } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

import { logIn, logOut } from '../../auth/store/actions';
import {
  addFormSearchExplore,
  loadSupplier,
  loadSuppliers,
  resetPassword,
  searchExplore,
  submitAddSupplier,
  submitSupplier,
  submitAddSupplierAgreement,
} from './actions';

import { suppliersPerPage } from '../../../../common/config';

export const initialState = {
  appsForFilter: [],
  suppliers: [],

  selectedSupplier: null,
  selectedSupplierAgreements: [],
  selectedSupplierId: null,

  isLoadingSuppliers: false,
  isLoadingSelected: false,
 
  numTotal: 0,
  perPage: 0,

  currentRequestId: null,
  isSubmitting: false
  
  // Form storage stuff
  // accessForForm: {},
  // addFormExploreItems: [],
  // exploreSearchResults: [],
};

// Common log out callback
const commonLogOutCallback = (state, action) => {
  return { ...initialState };
};

// Created manageSuppliers store slice
const manageSuppliersSlice = createSlice({
  name: 'manageSuppliers',

  initialState,

  reducers: {
    // Select supplier
    selectSupplier: (state, action) => {
      state.selectedSupplier = null;
      state.selectedSupplierId = action.payload.id;
    },

    // Update app filter
    setAppFilter: (state, action) => {
      const { id, appFilterState } = action.payload;
      const thisApp = state.appsForFilter.find(a => a.id === id);
      if (thisApp) {
        thisApp.active = !!appFilterState;
        state.appsForFilter = [...state.appsForFilter];
      }
    },

    // Clear search results
    clearExploreSearchResults: (state) => {
      state.exploreSearchResults = [];
    },


    // Set Access value
    setAccessValue: (state, action) => {
      // console.log('setAccessValue reducer payload', action.payload);
      const { key, value } = action.payload;
     
      const accessForForm = { ...state.accessForForm };
      accessForForm[key] = value;

      state.accessForForm = accessForForm;
    },

  },

  extraReducers: builder => {
    // Load supplier PENDING
    builder.addCase(loadSuppliers.pending, state => {
      // console.log('REDUCED: loadSuppliers.pending');
      state.isLoadingSuppliers = true;
      state.selectedSupplierId = null;
    });
    // Load supplier FULFILLED
    builder.addCase(loadSuppliers.fulfilled, (state, action) => {
      // console.log('REDUCED: loadSuppliers.fulfilled');
      const { data = [], meta = {} } = action.payload;

      return {
        ...state,
        suppliers: data,
        isLoadingSuppliers: false,
        numTotal: meta.numTotal || 0,
        perPage: meta.perPage || suppliersPerPage
      };
    });
    // Load supplier REJECTED
    builder.addCase(loadSuppliers.rejected, state => {
      // console.log('REDUCED: loadSuppliers.rejected');
      return {
        ...state,
        suppliers: [],
        isLoadingSuppliers: false,
        numTotal: 0,
        perPage: suppliersPerPage
      };
    });

    // Load selected supplier PENDING
    builder.addCase(loadSupplier.pending, state => {
      state.isLoadingSelected = true;
      state.selectedSupplier = null;
    });
    // Load selected supplier FULFILLED
    builder.addCase(loadSupplier.fulfilled, (state, action) => {
      const { data = null } = action.payload;

      return {
        ...state,
        isLoadingSelected: false,
        selectedSupplier: data.supplier,
        selectedSupplierAgreements: data.agreements
      };
    });
    // Load selected supplier REJECTED
    builder.addCase(loadSupplier.rejected, state => {
      return {
        ...state,
        selectedSupplier: null,
        isLoadingSelected: false
      };
    });

    // Submit add supplier PENDING
    builder.addCase(submitAddSupplier.pending, (state, action) => {
      // console.log('SUB PEND');
      if (!state.isSubmitting) {
        state.currentRequestId = action.meta.requestId;
        state.isSubmitting = true;
      }
    });
    // Submit add supplier FULFILLED
    builder.addCase(submitAddSupplier.fulfilled, (state, action) => {
      // console.log('SUB DONE', action.payload.data);

      if (
        state.isSubmitting &&
        state.currentRequestId === action.meta.requestId
      ) {
        const { data } = action.payload;
        return {
          ...state,
          // selectedSupplierId: data.id,
          // selectedSupplier: data,

          // Resets
          currentRequestId: undefined,
          isSubmitting: false
        };
      }
    });
    // Submit add supplier REJECTED
    builder.addCase(submitAddSupplier.rejected, (state, action) => {
      // console.log('SUB FAIL');
      if (
        state.isSubmitting &&
        state.currentRequestId === action.meta.requestId
      ) {
        state.isSubmitting = false;
        state.currentRequestId = undefined;
      }
    });

    // Submit supplier PENDING
    builder.addCase(submitSupplier.pending, (state, action) => {
      // console.log('SUB PEND');
      if (!state.isSubmitting) {
        state.currentRequestId = action.meta.requestId;
        state.isSubmitting = true;
      }
    });
    // Submit supplier FULFILLED
    builder.addCase(submitSupplier.fulfilled, (state, action) => {
      // console.log('SUB DONE', action.payload.data);
      if (
        state.isSubmitting &&
        state.currentRequestId === action.meta.requestId
      ) {
        return {
          ...state,
          selectedsupplier: action.payload.data,

          // Resets
          currentRequestId: undefined,
          isSubmitting: false
        };
      }
    });
    // Submit supplier REJECTED
    builder.addCase(submitSupplier.rejected, (state, action) => {
      // console.log('SUB FAIL');
      if (
        state.isSubmitting &&
        state.currentRequestId === action.meta.requestId
      ) {
        state.isSubmitting = false;
        state.currentRequestId = undefined;
      }
    });

    // Submit add supplier agreement PENDING
    builder.addCase(submitAddSupplierAgreement.pending, (state, action) => {
      // console.log('SUB AG PEND');
      if (!state.isSubmitting) {
        state.currentRequestId = action.meta.requestId;
        state.isSubmitting = true;
      }
    });
    // Submit add supplier agreement FULFILLED
    builder.addCase(submitAddSupplierAgreement.fulfilled, (state, action) => {
      // console.log('SUB AG DONE');
      if (
        state.isSubmitting &&
        state.currentRequestId === action.meta.requestId
      ) {
        return {
          ...state,
          selectedSupplier: null, // Mark as null to force reload

          // Resets
          currentRequestId: undefined,
          isSubmitting: false
        };
      }
    });
    // Submit add supplier agreement REJECTED
    builder.addCase(submitAddSupplierAgreement.rejected, (state, action) => {
      // console.log('SUB AG FAIL');
      if (
        state.isSubmitting &&
        state.currentRequestId === action.meta.requestId
      ) {
        state.isSubmitting = false;
        state.currentRequestId = undefined;
      }
    });

    // On log in FULFILLED
    builder.addCase(logIn.fulfilled, (state, action) => {
      state.appsForFilter = action.payload.data.allowedApps.map(app => {
        return {
          active: true,
          id: app.appId,
          label: app.name
        };
      });
    });

    // Log out fulfilled or rejected due to error - reset state
    builder.addCase(logOut.fulfilled, commonLogOutCallback);
    builder.addCase(logOut.rejected, commonLogOutCallback);
  }
});

export const whitelist = [
  'appsForFilter',
  'numTotal',
  'perPage',
  'selectedSupplierId'
];

export const {
  clearExploreSearchResults,
  selectSupplier,
  setAccessValue,
  setAppFilter
} = manageSuppliersSlice.actions;
export const reducer = manageSuppliersSlice.reducer;
