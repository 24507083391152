import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import Datetime from 'react-datetime';
import Countdown from 'react-countdown';
import moment from 'moment-timezone';

// Bootstrap
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import Table from 'react-bootstrap/Table';

// Components
import { EmbeddedTable } from '../../../lib/components/EmbeddedTable';
import { FauxCheckBox } from '../../../lib/components/FauxCheckBox';
// import { FauxRadioButton } from '../../../lib/components/FauxRadioButton';

// Actions
import {
  loadSupplier,
  submitAddSupplierAgreement
} from '../store/actions';
import { logOut } from '../../auth/store/actions';
// import {
//   addAddFormExploreItem,
//   removeAddFormExploreItem,

//   addExploreItem,
//   removeExploreItem,

//   clearExploreSearchResults
// } from '../store/slice';

// Util
import { devLog } from '../../../lib/util/devLog';
import { getAppIdsFromFields } from '../../../lib/util/getAppIdsFromFields';

// Prep default values
const getDefaultValues = (appsForFilter) => {
  // Allowed apps defaults
  const allowedAppsDefaults = Object.fromEntries(
    appsForFilter.map(app => [`assocApps_${app.id}`, null])
  );

  // const isActive = false;
  // const description = '';
  // const durationAmount = '';
  // const durationType = '';
  const starts = '';
  const ends = '';
  const priceCurrency = '';
  const priceAmount = '0.00';
  const priceDisplay = '';


  const description = "f"
  const durationAmount = "1"
  const durationType = "month"
  // const starts = "2023-03-06T00:00:00.000Z";
  // const ends = "2023-04-02T00:00:00.000Z";
  // const isActive = true
  // const priceAmount = "500"
  // const priceCurrency = "aed"
  // const priceDisplay = "AED 500"



  return {
    ...allowedAppsDefaults,
    // isActive,
    description,
    durationAmount,
    durationType,
    starts,
    ends,
    priceCurrency,
    priceAmount,
    priceDisplay
  }
};

export const AddSupplierAgreementForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const appsForFilter = useSelector(
    ({ manageSuppliers }) => manageSuppliers.appsForFilter
  );
  const isLoadingSelected = useSelector(
    ({ manageSuppliers }) => manageSuppliers.isLoadingSelected
  );
  const isSubmitting = useSelector(
    ({ manageSuppliers }) => manageSuppliers.isSubmitting
  );
  const supplier = useSelector(
    ({ manageSuppliers }) => manageSuppliers.selectedSupplier
  );
  const selectedSupplierId = useSelector(
    ({ manageSuppliers }) => manageSuppliers.selectedSupplierId
  );

  // Get form defaults
  let defaultValues = getDefaultValues(appsForFilter);

  useEffect(() => {
    if (selectedSupplierId && !supplier && !isLoadingSelected) {
      dispatch(loadSupplier({ id: selectedSupplierId }))
        .unwrap()
        .catch(err => {
          setError(
            err.meta && err.meta.field ? err.meta.field : 'form',
            {
              type: 'server',
              message: err.error
            }
          );
          navigate('/manage-suppliers');
        });
    }
  }, [supplier]);

  const {
    clearErrors,
    control,
    getValues,
    handleSubmit,
    reset,
    setError,
    setValue,
    watch,
    formState: { errors, isSubmitSuccessful }
  } = useForm({
    defaultValues
  });

  // Event handlers

  // With have onSubmit and onSubmitForm below to allow manual clearErrors()
  // as react-hook-form requires manual clearing for general form errors.
  const onSubmit = data => {
    if (isSubmitting) {
      return;
    }

    // Get allowed app IDs
    const appIds = getAppIdsFromFields(data, 'assocApps_');

    // Extract data from formdata object, and add processed fields
    const submitData = (({
      status,
      description,
      durationAmount,
      durationType,
      starts,
      ends,
      priceCurrency,
      priceAmount,
      priceDisplay
    }) =>
    ({
      // isActive,
      description,
      durationAmount,
      durationType,
      starts,
      ends,
      priceCurrency,
      priceAmount,
      priceDisplay
    }))(data);

    submitData.appIds = appIds;

    // At least one app
    if (submitData.appIds.length < 1) {
      return setError(
        'assocApps',
        {
          type: 'custom',
          message: 'Select at least one app.'
        }
      );
    }

    if (!submitData.durationAmount || !submitData.durationType) {
      if (!submitData.starts || !submitData.ends) {
        return setError(
          'durationType',
          {
            type: 'custom',
            message: 'Please set a duration, or Start and End dates.'
          }
        );
      }
    }

    if (submitData.ends && !submitData.starts) {
      return setError(
        'form',
        {
          type: 'custom',
          message: 'If entering an End Date, please provide a Start Date.'
        }
      );
    }

    submitData.starts = submitData.starts ?
      moment(submitData.starts).toISOString() :
      undefined;
    submitData.ends = submitData.ends ?
      moment(submitData.ends).toISOString() :
      undefined;

    // console.log('SENDING TO ACTION', submitData);

    return dispatch(submitAddSupplierAgreement(submitData))
      .unwrap()
      .then(() => {
        const url = selectedSupplierId ?
          `/manage-suppliers/${selectedSupplierId}` :
          '/manage-suppliers';
        return setTimeout(() => navigate(url), 5000);
      })
      .catch(err => {
        if (err && err?.code === 401) {
          return dispatch(logOut());
        }
        else {
          setError(
            err?.meta && err?.meta.field ? err.meta.field : 'form',
            {
              type: 'server',
              message: err.error || err.toString()
            }
          );
        }
      });
  };
  const onSubmitForm = e => {
    clearErrors();
    return handleSubmit(onSubmit)(e);
  };

  // / Event handlers

  /*
  supplierId: String,
 
  SECTION
  
  LEFT
  active: { type: Boolean, default: false },
  RIGHT
  appIds: [String],

  FULL
  description: String, FULL WIDTH

  LEFT
  duration: {
    amount: Number,
    type: { type: String, enum: ['day', 'week', 'month', 'year'] }
  },
  RIGHT
  starts: Date,
  ends: Date,

  SECTION
  PAYMENT INFO (show payment deets if id)
  price: {
    amount: Number,
    amountDisplay: String,
    currencyCode: String,
    currencySymbol: String
  },
  paymentId: String, // Updated after successful payment

  SECTION
  notes: String
  */

  return (
    <>
      <h1 className="mb-4">
        <Link className="text-muted" to="/manage-suppliers">
          Manage Suppliers
        </Link>
        {' / '}
        <Link
          className="text-muted"
          to={`/manage-suppliers/${supplier ? supplier.id : ''}`}
        >
          {supplier ? supplier.name : 'Back'}
        </Link>
        {' / Add Agreement'}
      </h1>

      <Form onSubmit={onSubmitForm}>

        {isSubmitSuccessful && <Alert variant="success">
          Agreement saved. <span className="text-muted">
            Back to supplier in <Countdown
              date={Date.now() + 5000}
              renderer={({ seconds }) => <span>{seconds}s</span>}
            />
          </span>
        </Alert>}

        {errors.form && <Alert variant="danger">
          {errors.form?.message}
        </Alert>}

        <Row className="mb-3">
          <Col className="mb-3" lg={8} sm={12}>

            {/* Agreement details */}
            <Container className="bg-white p-3">
              <Row className="mb-3">

                {/* LEFT COLUMN */}
                <Col lg={6}>

                  {/* Status */}
                  <Form.Group className="mb-3">
                    <span className="fs-7 text-muted text-uppercase">
                      Agreement Status
                    </span>

                    <div className="fs-7 mt-1 text-muted">
                      <ul>
                        <li>
                          <strong>"Pending Payment"</strong> - if payment is
                          needed.
                        </li>
                        <li>
                          <strong>"Pending Start"</strong> - ready, awaiting
                          Start Date.
                        </li>
                        <li>
                          <strong>"Active"</strong> - agreement is underway.
                        </li>
                        <li>
                          <strong>"Expired"</strong> - the End Date has passed.
                        </li>
                        <li>
                          <strong>"Cancelled"</strong> - manually cancelled.
                        </li>
                      </ul>
                    </div>
                  </Form.Group>

                  {/* Payment Status */}
                  <Form.Group>
                    <span className="fs-7 text-muted text-uppercase">
                      Payment Status
                    </span>

                    <div className="fs-7 mt-1 text-muted">
                      <ul>
                        <li>
                          <strong>"Pending"</strong> - if payment is needed.
                        </li>
                        <li>
                          <strong>"Paid"</strong> - success. Automatically set
                          after Stripe payment, or can be set manually if paid
                          another way.
                        </li>
                        <li>
                          <strong>"N/A"</strong> - price is zero, agreed
                          access is free.
                        </li>
                      </ul>
                    </div>
                  </Form.Group>

                </Col>

                {/* RIGHT COLUMN */}
                <Col lg={6}>
                  {/* Associated Apps */}
                  <Form.Group>
                    <span className="fs-7 text-muted text-uppercase">
                      Associated Apps
                    </span>

                    {appsForFilter.map(app => <Controller
                      control={control}
                      defaultValue={false}
                      key={`assocApps_${app.id}`}
                      name={`assocApps_${app.id}`}
                      render={({
                        field: { ref, value }
                      }) => {
                        return (
                          <Stack
                            className={
                              `my-1${errors.assocApps ? ' is-invalid' : ''}`
                            }
                            direction="horizontal"
                            gap={2}
                            onClick={() => {
                              setValue(
                                `assocApps_${app.id}`,
                                value === true ? false : true
                              )
                            }}
                            ref={ref}
                            role="button"
                          >
                            <FauxCheckBox
                              active={value}
                              label={app.label}
                            />
                          </Stack>
                        );
                      }}
                    />)}

                    {errors.assocApps &&
                      <Form.Control.Feedback type="invalid">
                        {errors.assocApps?.message}
                      </Form.Control.Feedback>}

                    <Form.Text className="fs-7 text-muted">
                      The app this agreement relates to.
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">

                {/* FULL WIDTH COLUMN */}
                <Col lg={12}>

                  {/* Agreement description */}
                  <Form.Group>
                    <span className="fs-7 text-muted text-uppercase">
                      Agreement Description
                    </span>

                    <Controller
                      defaultValue=""
                      control={control}
                      name="description"
                      render={({
                        field: { onBlur, onChange, ref, value },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState
                      }) => (<Form.Control
                        as="textarea"
                        isInvalid={error}
                        onBlur={onBlur}
                        onChange={onChange}
                        placeholder="Locations, features enabled, how long..."
                        ref={ref}
                        rows="7"
                        value={value}
                      />)}
                    />

                    {errors.description &&
                      <Form.Control.Feedback type="invalid">
                        {errors.description?.message}
                      </Form.Control.Feedback>}

                    <div className="mt-1">
                      <Form.Text className="fs-7 text-muted">
                        A description of what is agreed, to display to the
                        supplier when they go to make a payment, or view the
                        payment after the fact, describing what was paid for. Or
                        describe an arrangement, if we have agreed free access.
                      </Form.Text>
                    </div>
                  </Form.Group>

                </Col>

              </Row>

              <Row className="mb-3">

                {/* LEFT COLUMN */}
                <Col lg={6}>

                  <span className="fs-7 text-muted text-uppercase">
                    Agreement Duration
                  </span>

                  <Row className="mb-1">
                    <Col lg={4}>
                      {/* Duration length */}
                      <Form.Group>
                        <Controller
                          defaultValue=""
                          control={control}
                          name="durationAmount"
                          render={({
                            field: { onBlur, onChange, ref, value },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState
                          }) => (<Form.Control
                            isInvalid={error}
                            onBlur={onBlur}
                            onChange={onChange}
                            placeholder="Length"
                            ref={ref}
                            type="number"
                            value={value}
                          />)}
                        />

                        {errors.durationAmount &&
                          <Form.Control.Feedback type="invalid">
                            {errors.durationAmount?.message}
                          </Form.Control.Feedback>}
                      </Form.Group>
                    </Col>

                    <Col lg={8}>
                      <Form.Group>
                        <Controller
                          control={control}
                          defaultValue={false}
                          name="durationType"
                          render={({
                            field: { onChange, ref, value }
                          }) => {
                            return (
                              <Form.Select
                                className={
                                  errors.durationType ? 'is-invalid' : ''
                                }
                                onChange={onChange}
                                ref={ref}
                                value={value}
                              >
                                <option value="">Duration Type</option>
                                <option value="day">Day(s)</option>
                                <option value="week">Week(s)</option>
                                <option value="month">Month(s)</option>
                                <option value="year">Year(s)</option>
                              </Form.Select>
                            );
                          }}
                        />

                        {errors.durationType &&
                          <Form.Control.Feedback type="invalid">
                            {errors.durationType?.message}
                          </Form.Control.Feedback>}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Text className="fs-7 text-muted">
                    eg. If you select a duration of 1 year, and supplier makes
                    payment on Nov 15th 2022, that automatically becomes the
                    Start date, End date is automatically set to Nov 14th 2023.
                    Manually setting Start and End dates overrides this.
                  </Form.Text>
                </Col>

                {/* RIGHT COLUMN */}
                <Col className="mb-3" lg={6}>

                  {/* Start Date */}
                  <Form.Group className="mb-3">
                    <span className="fs-7 text-muted text-uppercase">
                      Start Date
                    </span>

                    <Controller
                      defaultValue=""
                      control={control}
                      name="starts"
                      render={({
                        field: { onBlur, onChange, ref, value },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState
                      }) => (<Datetime
                        dateFormat="MMM Do YYYY"
                        isInvalid={error}
                        onChange={onChange}
                        ref={ref}
                        timeFormat="HH:mm"
                        timeConstraints={{
                          minutes: { min: 0, max: 59, step: 15 }
                        }}
                        value={value}
                      />)}
                    />

                    {errors.starts && <Form.Control.Feedback type="invalid">
                      {errors.starts?.message}
                    </Form.Control.Feedback>}
                  </Form.Group>

                  {/* End Date */}
                  <Form.Group className="mb-3">
                    <span className="fs-7 text-muted text-uppercase">
                      End Date and Time
                    </span>

                    <Controller
                      defaultValue=""
                      control={control}
                      name="ends"
                      render={({
                        field: { onBlur, onChange, ref, value },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState
                      }) => (<Datetime
                        dateFormat="MMM Do YYYY"
                        isInvalid={error}
                        onChange={onChange}
                        ref={ref}
                        timeFormat="HH:mm"
                        timeConstraints={{
                          minutes: { min: 0, max: 59, step: 15 }
                        }}
                        value={value}
                      />)}
                    />

                    {errors.ends && <Form.Control.Feedback type="invalid">
                      {errors.ends?.message}
                    </Form.Control.Feedback>}
                  </Form.Group>

                </Col>

              </Row>

              <Row className="mb-3">

                {/* LEFT COLUMN */}
                <Col lg={4}>
                  {/* Price currency */}
                  <span className="fs-7 text-muted text-uppercase">
                    Currency
                  </span>

                  <Form.Group>
                    <Controller
                      control={control}
                      defaultValue={false}
                      name="priceCurrency"
                      render={({
                        field: { onChange, ref, value }
                      }) => {
                        return (
                          <Form.Select
                            className={
                              errors.priceCurrency ? 'is-invalid' : ''
                            }
                            onChange={onChange}
                            ref={ref}
                            value={value}
                          >
                            <option value="">Choose Currency</option>
                            <option value="aed">AED Dirham</option>
                            <option value="usd">$ Dollar</option>
                            <option value="gbp">£ Pound</option>
                          </Form.Select>
                        );
                      }}
                      rules={{
                        validate: v => {
                          const priceAmt = getValues().priceAmount;
                          if (!isNaN(priceAmt) && priceAmt > 0) {
                            return v !== '' || 'Please choose a currency.';
                          }
                          else {
                            return true;
                          }
                        }
                      }}
                    />

                    {errors.priceCurrency &&
                      <Form.Control.Feedback type="invalid">
                        {errors.priceCurrency?.message}
                      </Form.Control.Feedback>}
                  </Form.Group>
                </Col>

                {/* MIDDLE COLUMN */}
                <Col lg={4}>
                  {/* Price amount */}
                  <span className="fs-7 text-muted text-uppercase">
                    Price (amount)
                  </span>

                  <Form.Group>
                    <Controller
                      defaultValue=""
                      control={control}
                      name="priceAmount"
                      render={({
                        field: { onBlur, onChange, ref, value },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState
                      }) => (<Form.Control
                        isInvalid={error}
                        onBlur={onBlur}
                        onChange={onChange}
                        placeholder="eg. 299.99"
                        ref={ref}
                        type="number"
                        value={value}
                      />)}
                      rules={{ required: 'Please enter a price.' }}
                    />

                    {errors.priceAmount &&
                      <Form.Control.Feedback type="invalid">
                        {errors.priceAmount?.message}
                      </Form.Control.Feedback>}

                    <Form.Text className="fs-7 text-muted">
                      The price as a raw number only, eg.{' '}
                      <span className="fst-italic">299.99</span>{' '}
                      or <span className="fst-italic">1000</span>, no
                      commas or currency symbols.
                    </Form.Text>
                  </Form.Group>
                </Col>

                {/* RIGHT COLUMN */}
                <Col lg={4}>
                  {/* Display price */}
                  <span className="fs-7 text-muted text-uppercase">
                    Display Price
                  </span>

                  <Form.Group>
                    <Controller
                      defaultValue=""
                      control={control}
                      name="priceDisplay"
                      render={({
                        field: { onBlur, onChange, ref, value },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState
                      }) => (<Form.Control
                        isInvalid={error}
                        onBlur={onBlur}
                        onChange={onChange}
                        placeholder="eg. £299.99"
                        ref={ref}
                        value={value}
                      />)}
                      rules={{
                        validate: v => {
                          const priceAmt = getValues().priceAmount;
                          if (!isNaN(priceAmt) && priceAmt > 0) {
                            return v !== '' || 'Please enter a display price.';
                          }
                          else {
                            return true;
                          }
                        }
                      }}
                    />

                    {errors.priceDisplay &&
                      <Form.Control.Feedback type="invalid">
                        {errors.priceDisplay?.message}
                      </Form.Control.Feedback>}

                    <Form.Text className="fs-7 text-muted">
                      The price as it will be displayed to the supplier,
                      including the currency symbol, eg.{' '}
                      <span className="fst-italic">£299.99</span> or {' '}
                      <span className="fst-italic">1,000 GBP</span>.
                    </Form.Text>
                  </Form.Group>
                </Col>

              </Row>
            </Container>
          </Col>

          {/* Sidebar */}
          <Col lg={4}>
            <div className="bg-white p-3 mb-2">
              <Button
                className="w-100"
                type="submit"
                variant="primary"
              >
                Add Agreement
              </Button>
            </div>
          </Col>
        </Row>

      </Form>
    </>
  );
};
