'use strict';

import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiGet, apiPost } from '../../../lib/util/api';

import { listingsPerPage } from '../../../../common/config';

// Load listings
export const loadListings = createAsyncThunk(
  'explore/loadPage',
  async (opts = {}, { getState, rejectWithValue }) => {
    let { page = 0 } = opts;

    try {
      // Get filters
      const { access, isTWMEAdmin, isSuperAdmin } = getState().auth;
      const isAdmin = (isTWMEAdmin || isSuperAdmin) || false;
      const { appsForFilter, incUnpublishedFilter } = getState().explore;
      const selectedApps = appsForFilter
        .filter(app => app.active === true);

      // Fail out if there are no apps to select - ie. no access to apps
      if (appsForFilter.length < 1) {
        throw { code: 401 };
      }

      // Build request params      
      const params = {
        incUnpublished: incUnpublishedFilter,
        page,
        perPage: listingsPerPage
      };

      if (selectedApps.length > 0) {
        params.appIds = selectedApps.map(app => app.id).join(',');
      }

      // Send the request
      const response = await apiGet('/cp-api/manage-explore', { params });
      return response.data;
    }
    catch (err) {
      return rejectWithValue(err);
    }
  }
);

// Load selected listing
export const loadListing = createAsyncThunk(
  'explore/loadSelected',
  async (opts = {}, { rejectWithValue }) => {
    let { id } = opts;

    try {
      // Send the request
      const response = await apiGet(`/cp-api/manage-explore/${id}`);
      return response.data;
    }
    catch (err) {
      throw rejectWithValue(err);
    }
  }
);

// Update a single listing field
export const updateSingleField = createAsyncThunk(
  'explore/updateSingleField',
  async (opts = {}, { getState, requestId, rejectWithValue }) => {
    let { id, field, updateSelected = null, value } = opts;

    const { updateFieldRequestId, isUpdatingField } = getState().explore;
    if (!isUpdatingField || requestId !== updateFieldRequestId) {
      return;
    }

    let endpoint = '';
    switch (true) {
      case field === 'showOnLatest' && value === true:
        endpoint = 'latest-show';
        break;

      case field === 'showOnLatest' && value === false:
        endpoint = 'latest-unshow';
        break;

      case field === 'featuredOnLatest' && value === true:
        endpoint = 'latest-feature';
        break;

      case field === 'featuredOnLatest' && value === false:
        endpoint = 'latest-unfeature';
        break;
    }

    try {
      const response = await apiPost(
        `/cp-api/manage-explore/${id}/${endpoint}`
      );

      if (!response.data?.data || !Array.isArray(response.data?.data)) {
        throw 'Unknown error updating listings. Please try again.';
      }

      // If this is called from the list, we need to update the collection
      if (updateSelected !== true) {
        return response.data;
      }
      // If called from the Edit form, we can update the field directly
      else {
        return {
          data: response.data.data,
          field,
          selectedListingId: id,
          value
        }
      }
    }
    catch (err) {
      throw rejectWithValue(err);
    }
  }
);

// Submit listing changes
export const submitListing = createAsyncThunk(
  'explore/submit',
  async (opts = {}, { getState, requestId, rejectWithValue }) => {
    let { id, data } = opts;

    const { currentRequestId, isSubmitting } = getState().explore;
    if (!isSubmitting || requestId !== currentRequestId) {
      return;
    }

    try {
      const response = await apiPost(
        `/cp-api/manage-explore/${id}`,
        { data }
      );

      return response.data;
    }
    catch (err) {
      throw rejectWithValue(err);
    }
  }
);

// ----- Categories -----

// Load categories
export const loadCategories = createAsyncThunk(
  'explore/loadCategories',
  async (opts = {}, { rejectWithValue }) => {
    let { id } = opts;

    try {
      // Send the request
      const response = await apiGet(
        '/cp-api/manage-explore/categories',
        { params: { appId: '60a38eeb7a796735244f4092' } }
      );
      return response.data;
    }
    catch (err) {
      throw rejectWithValue(err);
    }
  }
);

// Submit categories changes
export const submitCategories = createAsyncThunk(
  'explore/submitCategories',
  async (opts = {}, { getState, requestId, rejectWithValue }) => {
    let { data: cats } = opts;

    const { currentCatRequestId, isSubmitting } = getState().explore;
    if (!isSubmitting || requestId !== currentCatRequestId) {
      return;
    }

    try {
      const response = await apiPost(
        `/cp-api/manage-explore/categories`,
        {
          data: {
            appId: '60a38eeb7a796735244f4092',
            cats
          }
        }
      );

      return response.data;
    }
    catch (err) {
      throw rejectWithValue(err);
    }
  }
);

// ----- Filters -----

// Load filters
export const loadFilters = createAsyncThunk(
  'explore/loadFilters',
  async (opts = {}, { rejectWithValue }) => {
    let { id } = opts;

    try {
      // Send the request
      const response = await apiGet(
        '/cp-api/manage-explore/filters',
        { params: { appId: '60a38eeb7a796735244f4092' } }
      );
      return response.data;
    }
    catch (err) {
      throw rejectWithValue(err);
    }
  }
);

// Submit filters changes
export const submitFilters = createAsyncThunk(
  'explore/submitFilters',
  async (opts = {}, { getState, requestId, rejectWithValue }) => {
    let { data: filters } = opts;

    const { currentFilRequestId, isSubmitting } = getState().explore;
    if (!isSubmitting || requestId !== currentFilRequestId) {
      return;
    }

    try {
      const response = await apiPost(
        `/cp-api/manage-explore/filters`,
        {
          data: {
            appId: '60a38eeb7a796735244f4092',
            filters
          }
        }
      );

      return response.data;
    }
    catch (err) {
      throw rejectWithValue(err);
    }
  }
);

// ----- Price range -----

// Load price range
export const loadPriceRange = createAsyncThunk(
  'explore/loadPriceRange',
  async (opts = {}, { rejectWithValue }) => {
    let { id } = opts;

    try {
      // Send the request
      const response = await apiGet(
        '/cp-api/manage-explore/price-range',
        { params: { appId: '60a38eeb7a796735244f4092' } }
      );
      return response.data;
    }
    catch (err) {
      throw rejectWithValue(err);
    }
  }
);

// Submit price range changes
export const submitPriceRange = createAsyncThunk(
  'explore/submitPriceRange',
  async (opts = {}, { getState, requestId, rejectWithValue }) => {
    let { data: priceRange, id: prId } = opts;

    const { currentPriRequestId, isSubmitting } = getState().explore;
    if (!isSubmitting || requestId !== currentPriRequestId) {
      return;
    }

    try {
      const response = await apiPost(
        `/cp-api/manage-explore/price-range`,
        {
          data: {
            priceRange,
            prId
          }
        }
      );

      return response.data;
    }
    catch (err) {
      throw rejectWithValue(err);
    }
  }
);