import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

// Bootstrap
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import Table from 'react-bootstrap/Table';

import { SupplierListAppNames } from '../components/SupplierListAppNames';
import { DropDownFilter } from '../../../lib/components/DropDownFilter';
import { Pagination } from '../../../lib/components/Pagination';

import { loadSuppliers } from '../store/actions';
import { selectSupplier, setAppFilter } from '../store/slice';

import { useIsAdmin } from '../../../lib/hooks/useIsAdmin';

import { suppliersPerPage } from '../../../../common/config';

export const List = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const isAdmin = useIsAdmin();

  const page = searchParams.get('page') || 0;

  const [showNoSuppliers, setShowNoSuppliers] = useState(false);

  const access = useSelector(({ auth }) => auth.access);
  const isLoggedIn = useSelector(({ auth }) => auth.isLoggedIn);
  const appsForFilter = useSelector(
    ({ manageSuppliers }) => manageSuppliers.appsForFilter
  );
  const suppliers = useSelector(
    ({ manageSuppliers }) => manageSuppliers.suppliers
  );
  const isLoadingSuppliers = useSelector(
    ({ manageSuppliers }) => manageSuppliers.isLoadingSuppliers
  );
  const numTotal = useSelector(({ manageSuppliers }) => manageSuppliers.numTotal);
  const perPage = useSelector(({ manageSuppliers }) => manageSuppliers.perPage);

  // Shared load function
  const _loadSuppliers = ({ page }) => {
    return dispatch(loadSuppliers({ page }))
      .unwrap()
      .then(() => {
        setShowNoSuppliers(false);
      })
      .catch(err => {
        if (err && (err?.code === 401 || err?.code === 204)) {
          setShowNoSuppliers(true);
        }
      });
  };

  // Redirect if not logged in
  useEffect(() => {
    if (!isLoggedIn) {
      return navigate('/login', { replace: true });
    }
  }, [isLoggedIn]);

  // Redirect if shouldn't be here
  useEffect(() => {
    if (isAdmin.any !== true) {
      return navigate('/', { replace: true });
    }
  });

  // Loading listings if not already loading or found has no listings to show
  useEffect(() => {
    // console.log('UE[page], access, isLoadingSuppliers, showNoSuppliers, isAdmin', access, isLoadingSuppliers, showNoSuppliers, isAdmin);
    if (isAdmin.any === true && !isLoadingSuppliers && !showNoSuppliers) {
      _loadSuppliers({ page });
    }
  }, [page]);

  // Event handlers

  const onChangeAppFilter = (id, appFilterState) => {
    dispatch(setAppFilter({ id, appFilterState }));
    _loadSuppliers({ page: 0 });
  };

  const onClickSelectSupplier = id => {
    dispatch(selectSupplier({ id }));
  }

  const onClickAddSupplier = id => {
    return navigate('/manage-suppliers/add');
  }

  // / Event handlers

  // Fragment render fns

  const renderAppFilter = () => {
    return <DropDownFilter
      label="Apps"
      onChange={onChangeAppFilter}
      options={appsForFilter}
    />;
  };

  const renderEmailSearch = () => {
    return <EmailSearchField
      onClickSearch={() => { console.log('SEARH!'); }}
    />;
  };

  const renderAppsSummary = apps => {
    let appsStr = 'None Selected';
    if (apps.length > 0) {
      appsStr = apps.filter(a => a.active).map(a => a.label).join(' / ');
    }
    return (<div>
      <span className="fw-bold text-white-50">Apps:</span> {appsStr}
    </div>);
  };

  const renderAccess = access => {
    return (
      <>
        <span>lots of access</span>
      </>
    )
  };

  const renderAppNames = access => {
    return (
      <>
        <span>lots of apps</span>
      </>
    )
  };

  // / Fragment render fns

  return (
    <>
      <div className="d-flex align-items-start justify-content-between">
        <h1 className="mb-4">Manage Suppliers</h1>

        <Button onClick={onClickAddSupplier} variant="primary">
          <i className="bi-plus-lg" /> Add Supplier
        </Button>
      </div>

      <Stack className="mb-4" direction="horizontal" gap={3}>
        {renderAppFilter()}
        {/* {renderEmailSearch()} */}
      </Stack>

      <div className="bg-secondary d-flex flex-row fs-7 justify-content-between mb-1 p-3 text-light">
        {renderAppsSummary(appsForFilter)}
      </div>

      {showNoSuppliers && <Alert variant="info">
        No suppliers to edit.
      </Alert>}

      {!isLoadingSuppliers && suppliers && <Table borderless striped hover>
        <tbody>
          {suppliers.map(supplier =>
            <tr key={`splrs_list_${supplier.id}`}>
              <td className="p-0">
                <Link
                  className="d-flex flex-row align-items-center justify-content-between p-3 text-decoration-none text-body"
                  to={`/manage-suppliers/${supplier.id}`}
                  onClick={() => onClickSelectSupplier(supplier.id)}
                >

                  <Stack className="flex-grow-1" gap={1}>
                    <span>
                      <span className="fw-bold">
                        {supplier.name}
                      </span>
                    </span>

                    {supplier.appIds.length > 0 &&
                      < Stack className="fs-7" direction="horizontal" gap={2}>

                        <span className="text-muted">
                          <i className="bi-phone" />{' '}
                          <SupplierListAppNames
                            appIds={supplier.appIds}
                            apps={appsForFilter}
                          />
                        </span>
                      </Stack>}
                  </Stack>

                  <Button variant="primary">Edit</Button>

                </Link>
              </td>
            </tr>
          )}
        </tbody>
      </Table>}

      <div className="d-flex justify-content-center">
        <Pagination
          baseUrl="/manage-suppliers"
          maxLinksPerSide="3"
          numTotal={numTotal}
          page={page}
          perPage={suppliersPerPage}
        />
      </div>
    </>
  );
};
