import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { useIsAdmin } from '../hooks/useIsAdmin';

// import { logOut } from './../../features/auth/store/slice';
import { testDelay, getTest, logOut } from '../../features/auth/store/actions';

export const MainNavBar = () => {
  const dispatch = useDispatch();
  const loc = useLocation();
  const navigate = useNavigate();

  const access = useSelector(({ auth }) => auth.access);
  const isLoggedIn = useSelector(({ auth }) => auth.isLoggedIn);
  const isAdmin = useIsAdmin();

  // Run on change in isLoggedIn and route location
  useEffect(() => {
    // If not logged in, and not on the login page, go to there
    if (!isLoggedIn && loc.pathname !== '/login') {
      navigate('/login', { replace: true });
    }
  }, [isLoggedIn, loc]);

  // onClick Log Out
  const onClickLogout = evt => {
    evt.preventDefault();

    return dispatch(logOut());
  };

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand as={Link} to="/"><i className="bi-phone"></i> The World Made Easy</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" className="bi" fill="currentColor" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"></path>
          </svg>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          {isLoggedIn && <Nav className="me-auto">
            {(access?.manageExplore || isAdmin.any) &&
              <Nav.Link as={Link} to="/manage-explore">
                Explore
              </Nav.Link>}
            {/* {access.global && <Nav.Link as={Link} to="/users">Users</Nav.Link>} */}
            {access?.enquiries && <Nav.Link as={Link} to="/enquiries">
              Enquiries
            </Nav.Link>}
            {/* {(access?.editListings || access?.flashDeals) &&
              !isAdmin.any && <NavDropdown title="My Listings">
              <NavDropdown.Item as={Link} to="/my-listings">
                My Listings
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/flash-deals">
                Flash Deals
              </NavDropdown.Item>
            </NavDropdown>} */}
            {access?.editListings &&
              !isAdmin.any && <Nav.Link as={Link} to="/my-listings">
                My Listings
              </Nav.Link>}
            {/* {(access?.manageLogins || isAdmin.any) &&
              <Nav.Link as={Link} to="/manage-logins">
                Manage Logins
              </Nav.Link>} */}
            {(access?.approveFlashDeals || isAdmin.any) &&
              <NavDropdown title="Manage">
                <NavDropdown.Item as={Link} to="/moderate-flash-deals">
                  Moderate Flash Deals
                </NavDropdown.Item>
                {isAdmin.any && <NavDropdown.Divider />}
                {(access?.manageLogins || isAdmin.any) &&
                  <NavDropdown.Item as={Link} to="/manage-logins">
                    Logins
                  </NavDropdown.Item>}
                {(isAdmin.any) &&
                  <NavDropdown.Item as={Link} to="/manage-suppliers">
                    Suppliers
                  </NavDropdown.Item>}
                {/* <NavDropdown.Item as={Link} to="/moderate-listing-edits">
                  Listings Edits
                </NavDropdown.Item> */}
              </NavDropdown>}
          </Nav>}
          {isLoggedIn && <Nav>
            <a
              className="p-0 nav-link text-decoration-none"
              href="#logout"
              onClick={onClickLogout}
            >
              Log Out
            </a>
          </Nav>}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
