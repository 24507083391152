import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";

// Bootstrap
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import Table from 'react-bootstrap/Table';

// Components
import { EmbeddedTable }
  from '../../../lib/components/EmbeddedTable';
import { FauxCheckBox }
  from '../../../lib/components/FauxCheckBox';

// Actions
import {
  loadSupplier,
  resetPassword,
  searchExplore,
  submitSupplier
} from '../store/actions';
import { logOut } from '../../auth/store/actions';
// import {
//   addExploreItem,
//   clearExploreSearchResults,
//   removeExploreItem,
//   setAccessValue
// } from '../store/slice';

// Util
import { devLog } from '../../../lib/util/devLog';
import { friendlyDate } from '../../../lib/util/dates';

import {
  defaultAccess,
  supplierAgreementStatuses,
  supplierPaymentStatuses
} from '../../../../common/config';

// Prep default values
const getDefaultValues = (supplier, appsForFilter) => {
  // Allowed apps defaults
  const allowedAppsDefaults = Object.fromEntries(
    appsForFilter.map(app => [
      `allowedApps_${app.id}`,
      supplier?.appIds.some(appId => appId === app.id) || null
    ])
  );

  const name = supplier?.name || '';
  const email = supplier?.email || '';

  return supplier ? {
    ...allowedAppsDefaults,
    name,
    email
  } : {};
};

export const ManageSupplierForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const exploreSearchRef = useRef();

  const appsForFilter = useSelector(
    ({ manageSuppliers }) => manageSuppliers.appsForFilter
  );
  const isLoadingSelected = useSelector(
    ({ manageSuppliers }) => manageSuppliers.isLoadingSelected
  );
  const supplier = useSelector(
    ({ manageSuppliers }) => manageSuppliers.selectedSupplier
  );
  const agreements = useSelector(
    ({ manageSuppliers }) => manageSuppliers.selectedSupplierAgreements
  );
  const selectedSupplierId = useSelector(
    ({ manageSuppliers }) => manageSuppliers.selectedSupplierId
  );
  const isSubmitting = useSelector(
    ({ manageSuppliers }) => manageSuppliers.isSubmitting
  );

  // Get form defaults
  let defaultValues = getDefaultValues(
    supplier || null,
    appsForFilter
  );

  // TODO IF THERE IS NO SELECTEDSUPPLIERID GET THE QUERY PARAM AND SET IT - 
  // the rest of the code should take care of loading the supplier

  useEffect(() => {
    if (selectedSupplierId && !supplier && !isLoadingSelected) {
      dispatch(loadSupplier({ id: selectedSupplierId }));
    }
  }, [supplier]);

  const {
    clearErrors,
    control,
    getValues,
    handleSubmit,
    reset,
    setError,
    setValue,
    watch,
    formState: { errors, isSubmitSuccessful }
  } = useForm({
    defaultValues
  });

  // Reset the form on supplier load
  useEffect(() => {
    if (supplier) {
      reset(defaultValues);
    }
  }, [supplier, reset]);

  if (isLoadingSelected === true || !supplier) {
    return null;
  }

  // Extract appIds from the separate allowed apps fields
  const getAppIdsFromFields = data => {
    const appIds = [];
    const fieldKeys = Object.keys(data);
    for (let i = 0; i < fieldKeys.length; i++) {
      if (
        fieldKeys[i].indexOf('allowedApps_') === 0 &&
        data[fieldKeys[i]] === true
      ) {
        appIds.push(fieldKeys[i].slice(12));
      }
    }

    return appIds.join(',');
  };

  // Event handlers

  // With have onSubmit and onSubmitForm below to allow manual clearErrors()
  // as react-hook-form requires manual clearing for general form errors.
  const onSubmit = data => {
    if (isSubmitting) {
      return;
    }

    // Get allowed app IDs
    const appIds = getAppIdsFromFields(data);

    // Extract data from formdata object, and add processed fields
    const submitData = (({ email, name }) =>
      ({ email, name }))(data);

    submitData.appIds = appIds;

    return dispatch(submitSupplier({
      ...submitData,
      id: selectedSupplierId
    }))
      .unwrap()
      .then(({ data }) => {
        reset(getDefaultValues(data || null, appsForFilter));
      })
      .catch(err => {
        if (err && err?.code === 401) {
          return dispatch(logOut());
        }
        else {
          setError(
            err.meta && err.meta.field ? err.meta.field : 'form',
            {
              type: 'server',
              message: err.error || err.toString()
            }
          );
        }
      });
  };
  const onSubmitForm = e => {
    clearErrors();
    return handleSubmit(onSubmit)(e);
  };

  // Handle click create new agreement
  const onClickAddAgreement = () => {
    return navigate('add-agreement');
  };

  // Handle click manage agreement
  const onClickSelectAgreement = () => {
    console.log('bop');
    return '';
  };

  // Copy URL
  const onClickCopyURL = (e, url) => {
    e.stopPropagation();

    e.preventDefault();
    navigator.clipboard.writeText(url);
    return;
  };

  // / Event handlers

  // Render fns

  // Render the agreement duration
  const renderAgreementDuration = agreement => {
    if (agreement.starts && agreement.ends) {
      return (
        <span>
          {friendlyDate(agreement.starts)} to {friendlyDate(agreement.ends)}
        </span>
      );
    }
    else if (agreement.starts) {
      return (
        <span>
          {agreement.duration.amount} {agreement.duration.type}(s)
          from {friendlyDate(agreement.starts)}
        </span >
      );
    }
    else {
      return (
        <span>
          {agreement.duration.amount} {agreement.duration.type}(s)
          from payment completion
        </span>
      );
    }
  };

  // Render the agreement price
  const renderAgreementPrice = price => {
    if (price.amount > 0) {
      return price.amountDisplay;
    }

    return 'Free';
  };

  // Render the agreement status
  const renderAgreementStatus = status => {
    const agStatus = supplierAgreementStatuses.find(s => s.id === status);

    if (!agStatus) {
      return 'UNKNOWN';
    }

    return <span className={`fw-bold text-uppercase ${agStatus.colorClass}`}>
      {agStatus.label}
    </span>;
  };

  // Render the agreement payment status
  const renderPaymentStatus = status => {
    const agStatus = supplierPaymentStatuses.find(s => s.id === status);

    if (!agStatus) {
      return 'UNKNOWN';
    }

    return <span className={agStatus.colorClass}>{agStatus.label}</span>
  };

  // Render the payment URL
  const renderPaymentUrl = urlId => {
    const url = `https://localhost:5000/agreement-fulfilment/${urlId}`;

    return (
      <Stack
        className="mb-3 fs-7"
        direction="horizontal"
        gap={2}
      >
        <div className="text-muted text-uppercase">URL</div>

        <div>{url}</div>

        <div className="ms-auto">
          <Button
          onClick={e => onClickCopyURL(e, url)}
            type="button"
            variant="secondary"
          >
            <i className="bi-clipboard" />
          </Button>
        </div>
      </Stack>
    );
  };

  // Render supplier agreements
  const renderAgreements = () => {
    if (agreements.length === 0) {
      return (
        <Row><Col className="mb-2">
          {/* <Container className="bg-white p-3"> */}
          <Alert className="mb-0" variant="info">
            There are currently no agreements with this Supplier.
          </Alert>
          {/* </Container> */}
        </Col></Row>
      );
    }

    return (
      <Table borderless striped hover>
        <tbody>
          {agreements.map(agreement =>
            <tr key={`splrs_list_${agreement.id}`}>
              <td className="p-0">
                <Link
                  className="d-flex flex-row align-items-center justify-content-between text-decoration-none text-body"
                  to={`/manage-suppliers/${supplier.id}`}
                  onClick={() => onClickSelectAgreement(agreement.id)}
                >
                  <Container className="p-3">

                    <Row className="mb-2">
                      {/* Status */}
                      <Col>
                        <span className="fs-7 text-muted text-uppercase">
                          Status
                        </span>
                        <div>{renderAgreementStatus(agreement.status)}</div>
                      </Col>
                      {/* Payment status */}
                      <Col className="text-end">
                        <span className="fs-7 text-muted text-uppercase">
                          Payment Status
                        </span>
                        <div>{renderPaymentStatus(
                          agreement.paymentStatus
                        )}</div>
                      </Col>
                    </Row>

                    <Row className="mb-2">
                      {/* Apps */}
                      <Col>
                        <span className="fs-7 text-muted text-uppercase">
                          Apps
                        </span>
                        <div>{agreement.appIds}</div>
                      </Col>
                    </Row>

                    <Row className="mb-2">
                      {/* Duration */}
                      <Col>
                        <span className="fs-7 text-muted text-uppercase">
                          Duration
                        </span>
                        <div>{renderAgreementDuration(agreement)}</div>
                      </Col>
                    </Row>

                    <Row className="mb-2">
                      {/* Price */}
                      <Col>
                        <span className="fs-7 text-muted text-uppercase">
                          Price
                        </span>
                        <div>{renderAgreementPrice(agreement.price)}</div>
                      </Col>
                    </Row>

                    <Row className="mb-2">
                      {/* Description */}
                      <Col>
                        <span className="fs-7 text-muted text-uppercase">
                          Description
                        </span>
                        <div>{agreement.description}</div>
                      </Col>
                    </Row>

                    {/* Payment URL */}
                    {renderPaymentUrl(agreement.urlId)}

                    <Row className="mb-0">
                      <Col>
                        <Button
                          className="w-100"
                          type="submit"
                          variant="primary"
                        >
                          Manage Agreement
                        </Button>
                      </Col>
                    </Row>

                  </Container>

                </Link>
              </td>
            </tr>)}
        </tbody>
      </Table>
    );
  };

  // / Render fns

  return (
    <>
      <h1 className="mb-4">
        <Link className="text-muted" to="/manage-suppliers">Manage Suppliers</Link>
        {' / '}
        {supplier.name}
      </h1>

      <Form onSubmit={onSubmitForm}>

        {isSubmitSuccessful && <Alert variant="success">
          Changes saved!
        </Alert>}

        {errors.form && <Alert variant="danger">
          {errors.form?.message}
        </Alert>}

        <Row className="mb-2">
          {/* Supplier details */}
          <Col className="mb-2" lg={8} sm={12}>
            <Container className="bg-white p-3">
              <Row>

                {/* LEFT COLUMN */}
                <Col lg={6}>

                  {/* Name */}
                  <Form.Group className="mb-3">
                    <span className="fs-7 text-muted text-uppercase">
                      Name
                    </span>

                    <Controller
                      defaultValue=""
                      control={control}
                      name="name"
                      render={({
                        field: { onBlur, onChange, ref, value },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState
                      }) => (<Form.Control
                        onBlur={onBlur}
                        onChange={onChange}
                        isInvalid={error}
                        placeholder="eg. Hilton"
                        ref={ref}
                        value={value}
                      />)}
                      rules={{ required: true }}
                    />

                    {errors.name && <Form.Control.Feedback type="invalid">
                      {errors.name?.message}
                    </Form.Control.Feedback>}

                    <Form.Text className="fs-7 text-muted">
                      The name of the organisation. It may be the same as an
                      Explore location, if they only have one, or the name of a
                      group which oversees multiple locations.
                    </Form.Text>
                  </Form.Group>

                  {/* Contact email address */}
                  <Form.Group className="mb-3">
                    <span className="fs-7 text-muted text-uppercase">
                      Contact Email Address
                    </span>

                    <Controller
                      defaultValue=""
                      control={control}
                      name="email"
                      render={({
                        field: { onBlur, onChange, ref, value },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState
                      }) => (<Form.Control
                        onBlur={onBlur}
                        onChange={onChange}
                        isInvalid={error}
                        placeholder="eg. yourname@mailservice.com"
                        ref={ref}
                        type="email"
                        value={value}
                      />)}
                    />

                    {errors.email && <Form.Control.Feedback type="invalid">
                      {errors.email?.message}
                    </Form.Control.Feedback>}

                    <Form.Text className="fs-7 text-muted">
                      Used only to receive account status notifications, such as
                      confirmations of payments. Only enter an address which can
                      receive such sensitive messages in agreement with the
                      Supplier. This is not a Control Panel, so can be the same
                      email address as that, though they may receive multiple
                      payment confirmation emails. It is desirable, but
                      optional, in case the Supplier does not want to provide
                      one.
                    </Form.Text>
                  </Form.Group>

                </Col>

                {/* RIGHT COLUMN */}
                <Col lg={6}>

                  {/* Allowed Apps */}
                  <Form.Group className="mb-3">
                    <span className="fs-7 text-muted text-uppercase">
                      Allowed Apps
                    </span>

                    {appsForFilter.map(app => <Controller
                      control={control}
                      defaultValue={false}
                      key={`allowedApps_${app.id}`}
                      name={`allowedApps_${app.id}`}
                      render={({
                        field: { name, ref, value }
                      }) => {
                        return (
                          <Stack
                            className="my-1"
                            direction="horizontal"
                            gap={2}

                            onClick={() => {
                              setValue(
                                `allowedApps_${app.id}`,
                                value === true ? false : true
                              )
                            }}
                            ref={ref}
                            role="button"
                          >
                            {value && <i className="bi-check-square-fill text-primary" />}
                            {!value && <i className="bi-square" />}
                            <div>{app.label}</div>
                          </Stack>
                        );
                      }}
                    />)}

                    <Form.Text className="fs-7 text-muted">
                      The app(s) this login can access data for.
                    </Form.Text>
                  </Form.Group>

                </Col>
              </Row>
            </Container>
          </Col>

          {/* Manage Logins sidebar */}
          <Col lg={4}>
            <div className="bg-white p-3 mb-2">
              <Button
                className="w-100"
                type="submit"
                variant="primary"
              >
                Save Changes
              </Button>
            </div>
          </Col>
        </Row>

        {/* Reply form */}
        <Row><Col lg={8}>
          <div className="bg-secondary fs-5 mb-1 p-3 text-light d-flex flex-row align-items-center justify-content-between">
            <span>Agreements</span>

            <Button onClick={onClickAddAgreement} variant="primary">
              <i className="bi-plus-lg" /> Set Up Agreement
            </Button>
          </div>

          {renderAgreements()}

          {/* <ReplyForm
            isSubmitting={isSubmitting}
            selectedEnquiryId={selectedEnquiryId}
          /> */}
        </Col></Row>

      </Form>
    </>
  );
};
