import React from 'react';

export const SupplierListAppNames = ({ appIds, apps }) => {
  if (appIds.length < 1) {
    return null;
  }

  const appNames = appIds.map(
    appId => apps.find(app => app.id === appId).label
  );

  return (
    <span>
      {appNames.reduce((prev, curr) => [prev, ', ', curr])}
    </span>
  );
};
