import common from './common';

export const defaultAccess = common.defaultAccess;
export const enquiryStatuses = common.enquiryStatuses;
export const supplierAgreementStatuses = common.supplierAgreementStatuses;
export const supplierPaymentStatuses = common.supplierPaymentStatuses;
export const enquiriesPerPage = common.enquiriesPerPage;
export const listingsPerPage = common.listingsPerPage;
export const loginsPerPage = common.loginsPerPage;
export const suppliersPerPage = common.suppliersPerPage;
